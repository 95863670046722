/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import axios from "axios";
import { environment } from "../../environments/environment";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import FixedBanner from "../../components/FixedBanner/FixedBanner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faReddit } from "@fortawesome/free-brands-svg-icons";

const JobsPage = () => {
  document.title = `Jobs | LeadZLab`;

  const [jobs, setJobs] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [loading, setLoading] = useState(true);

  // * Nudges
  const [quota, setQuota] = useState({});

  const navigate = useNavigate();
  const { user } = useAuthContext();

  const getJobs = async () => {
    try {
      setLoading(true);
      let { data } = await axios.get(`${environment.BACKEND}/resources/job`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.accessToken}`,
        },
      });
      setJobs(data.data);
      setLoading(false);
    } catch (err) {
      console.log(err.response.data);
      if (err.response?.status === 400) window.alert("Please login again");
      setLoading(false);
    }
  };

  // * Check Quota
  const checkQuota = async () => {
    try {
      const { data } = await axios.get(`${environment.BACKEND}/user/quota`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.accessToken}`,
        },
      });
      setQuota(data.data);
    } catch (err) {
      console.log(err.response.data);
    }
  };

  useEffect(() => {
    getJobs();
    checkQuota();
  }, []);

  const handleCreateJob = () => {
    navigate("/create-job");
  };

  const handleDeleteJob = async () => {
    if (selectedJob) {
      try {
        await axios.delete(
          `${environment.BACKEND}/resources/job/${selectedJob._id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user?.accessToken}`,
            },
          }
        );
        setSelectedJob(null);
        setShowDeleteModal(false);
        getJobs();
      } catch (err) {
        console.log(err.response.data);
      }
    }
  };

  const handleCancelDelete = () => {
    setSelectedJob(null);
    setShowDeleteModal(false);
  };

  const handleOpenDeleteModal = (job) => {
    setSelectedJob(job);
    setShowDeleteModal(true);
  };

  return (
    <div className="container dashboard w-4/5">
      {/* Job limit nudge */}
      {quota.jobsCreated >= quota.jobsAllowed ? (
        <FixedBanner
          icon={<PriorityHighIcon />}
          message={`You will not be able to create any more lead matchers. Upgrade plan to create more. (${quota.jobsCreated}/${quota.jobsAllowed})`}
          actionText="Upgrade"
          actionLink="/pricing"
          color="gray"
        />
      ) : null}

      {/* Execution limit nudge */}
      {quota.executionsCreated >= quota.executionsAllowed ? (
        <FixedBanner
          icon={<PriorityHighIcon />}
          message={`Lead matchers have paused for today. Wait 24 hours for fresh leads, or upgrade plan. (${quota.executionsCreated}/${quota.executionsAllowed})`}
          actionText="Upgrade"
          actionLink="/pricing"
          color="gray"
        />
      ) : null}
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-3xl font-bold">Lead Matchers</h1>
        <button
          onClick={handleCreateJob}
          className="py-2 px-4 bg-gray-800 text-white font-bold rounded hover:bg-gray-600"
        >
          + Create Lead Matcher
        </button>
      </div>

      {!loading || <Skeleton count={5} />}
      {!loading &&
        (jobs.length === 0 ? (
          <div className="flex justify-center items-center h-40">
            <div>
              <p className="text-xl text-gray-600 text-center">
                No lead matcher found.
              </p>
            </div>
          </div>
        ) : (
          <div className="bg-white rounded-lg shadow">
            <table className="w-full">
              <thead>
                <tr>
                  <th className="py-2 px-4 bg-gray-100">Lead Matcher Name</th>
                  <th className="py-2 px-4 bg-gray-100">Type</th>
                  <th className="py-2 px-4 bg-gray-100">Last Run At</th>
                  <th className="py-2 px-4 bg-gray-100">Next Run At</th>
                  <th className="py-2 px-4 bg-gray-100">View</th>
                  <th className="py-2 px-4 bg-gray-100">Delete</th>
                </tr>
              </thead>
              <tbody>
                {jobs.map((job, index) => (
                  <tr
                    key={job._id}
                    className={`${index % 2 === 0 ? "white" : "bg-gray-100"} ${
                      job.is_processing ? "bg-yellow-100 text-gray-400" : ""
                    }`}
                  >
                    <td className="py-2 px-4 text-center">{job.jobName}</td>
                    <td className="py-2 px-4 text-center">
                      {job.type === "linkedin" && (
                        <FontAwesomeIcon
                          icon={faLinkedin}
                          style={{ color: "#0077b5", fontSize: "32px" }}
                        />
                      )}
                      {job.type === "reddit" && (
                        <FontAwesomeIcon
                          icon={faReddit}
                          style={{ color: " #ff4500", fontSize: "30px" }}
                        />
                      )}
                    </td>
                    <td className="py-2 px-4 text-center">
                      {job.lastExecuted !== "1970-01-01T00:00:00.000Z"
                        ? new Date(job.lastExecuted).toLocaleString([], {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          })
                        : "--"}
                    </td>
                    <td className="py-2 px-4 text-center">
                      {job.is_processing
                        ? "Processing... (This may take 1-2 minutes)"
                        : job.nextRun !== "1970-01-01T00:00:00.000Z"
                        ? new Date(job.nextRun).toLocaleString([], {
                            year: "numeric",
                            month: "short",
                            day: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          })
                        : "In a minute"}
                    </td>
                    <td className="py-2 px-4 text-center">
                      <a
                        href={`/job-detail?job_id=${job._id}`}
                        className="text-blue-500 hover:text-blue-700"
                      >
                        View
                      </a>
                    </td>
                    <td className="py-2 px-4 text-center">
                      <button
                        onClick={() => handleOpenDeleteModal(job)}
                        className={`text-red-500 hover:text-red-700 ${
                          job.is_processing
                            ? "cursor-not-allowed text-gray-400 hover:text-gray-400"
                            : ""
                        }`}
                        disabled={job.is_processing}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}

      {showDeleteModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 ">
          <div className="bg-white rounded-lg p-6">
            <p className="text-lg  mb-2 mt-5 mb-10 text-center">
              <span className="font-bold">
                Are you sure you want to delete the job?{" "}
              </span>
              <p>Deleting the job will delete all the executions as well.</p>
            </p>
            <div className="flex justify-end">
              <button
                onClick={handleCancelDelete}
                className="mr-2 px-4 py-2 bg-gray-200 text-gray-700 rounded hover:bg-gray-300"
              >
                Cancel
              </button>
              <button
                onClick={handleDeleteJob}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default JobsPage;
