// * Package Imports
import { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import axios from "axios";
import ReactSelect from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { FaClock } from "react-icons/fa";
import InputChipComponent from "../../components/InputChipComponent/InputChipComponent";
import { faLinkedin, faReddit } from "@fortawesome/free-brands-svg-icons";

// * Environment Imports
import { environment } from "../../environments/environment";

// * Import styles
import "./JobDetail.css";

// * Import Context
import { useAuthContext } from "../../hooks/useAuthContext";

// eslint-disable-next-line react/prop-types
const Tooltip = ({ text, children, direction = "bottom" }) => {
  const getDirectionClass = () => {
    switch (direction) {
      case "left":
        return "left-0 -translate-x-full top-1/2 -translate-y-1/2";
      case "right":
        return "right-0 -translate-x-0 top-1/2 -translate-y-1/2";
      case "top":
        return "top-0 -translate-x-1/2 -translate-y-full";
      case "bottom":
      default:
        return "bottom-0 -translate-x-1/2 translate-y-full";
    }
  };

  return (
    <div className="relative inline-block">
      <div className="group inline-block">
        {children}
        <div
          className={`absolute hidden group-hover:block bg-indigo-500 text-white text-sm md:text-md py-2 px-3 rounded-lg shadow-lg whitespace-nowrap transform ${getDirectionClass()}`}
        >
          {" "}
          <div className="whitespace-normal md:whitespace-nowrap  font-bold">
            {text}
          </div>
        </div>
      </div>
    </div>
  );
};

function JobDetails() {
  document.title = `Job Detail | LeadZLab`;

  const [job, setJob] = useState({
    _id: "",
    jobName: "",
    timeFilter: "",
    durationSpan: "",
    executions: [],
  });

  const [keywords, setKeywords] = useState([]);
  const [subReddits, setSubreddits] = useState([]);
  const [jobType, setJobType] = useState("linkedin");
  const [linkedInTimeFilter, setLinkedInTimeFilter] = useState("day");

  const location = useLocation();

  // * Auth context
  const { user } = useAuthContext();

  // * Handle Time change
  const handleTimeFilterChange = (selectedValue) => {
    setJob((prevJob) => ({
      ...prevJob,
      timeFilter: selectedValue,
    }));
  };

  const handleLinkedinTimeFilterChange = (value) => {
    setLinkedInTimeFilter(value);
  };

  // * Handle Job name change
  const handleJobNameChange = (e) => {
    setJob({ ...job, jobName: e.target.value });
  };

  // * Handle duration change
  const handleDurationChange = (e) => {
    setJob({ ...job, durationSpan: e.target.value });
  };

  // * Convert to chips from reddit query (decode)
  function convertFromRedditQuery(redditQuery) {
    const keywordsRegex = /"(.*?)"/g;
    const subredditsRegex = /subreddit:(\w+)/g;

    const keywords = [];
    const subreddits = [];

    let match;
    while ((match = keywordsRegex.exec(redditQuery)) !== null) {
      keywords.push(match[1]);
    }

    while ((match = subredditsRegex.exec(redditQuery)) !== null) {
      subreddits.push(match[1]);
    }

    return { keywords, subreddits };
  }

  const getJobById = async () => {
    try {
      const searchParams = new URLSearchParams(location.search);
      const jobId = searchParams.get("job_id");
      let { data } = await axios.get(
        `${environment.BACKEND}/resources/job/${jobId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.accessToken}`,
          },
        }
      );

      let chips = [];
      if (data.data.type === "reddit") {
        console.log("This is reddit?");
        chips = convertFromRedditQuery(data.data.q);
        setKeywords(chips.keywords);
      } else {
        setKeywords(data.data.q.split(" or "));
      }

      setSubreddits(chips.subreddits);
      setJobType(data.data.type);
      setJob(data.data);
      setLinkedInTimeFilter(data.data.timeFilter);
    } catch (err) {
      console.log(err.response.data);
    }
  };

  useEffect(() => {
    getJobById();
  }, []);

  // * Convert chips to reddit query (encode)
  const convertToRedditQuery = () => {
    try {
      const keywordQuery = keywords
        .map((keyword) => `"${keyword}"`)
        .join(" OR ");
      const subredditQuery = subReddits
        .map((subreddit) => `subreddit:${subreddit}`)
        .join(" OR ");
      return `(${keywordQuery}) AND (${subredditQuery})`;
    } catch (err) {
      return ``;
    }
  };

  const handleUpdateJob = async () => {
    try {
      if (parseInt(job.durationSpan) < 4 || parseInt(job.durationSpan) > 24)
        throw new Error("Interval must be between 4hr to 24hr");

      if (keywords.length === 0)
        throw new Error("Please enter at least one keyword to look for");

      if (jobType === "linkedin") {
        let linkedInQuery = keywords
          .map((keyword) => `${keyword}`)
          .join(" or ");

        if (linkedInQuery.length > 500)
          throw new Error(
            "Please ensure that the keywords do not take more than 500 characters. Please create another Lead Matcher to handle more keywords"
          );

        // eslint-disable-next-line no-unreachable
        await axios.patch(
          `${environment.BACKEND}/resources/job/${job._id}`,
          {
            jobName: job.jobName,
            q: linkedInQuery,
            timeFilter: linkedInTimeFilter,
            durationSpan: job.durationSpan,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user?.accessToken}`,
            },
          }
        );
      } else {
        if (convertToRedditQuery().length - 9 > 500)
          throw new Error(
            "Please ensure that the keywords and subreddits do not take more than 500 characters. Please create another Lead Matcher to handle more keyword and subreddits"
          );

        if (subReddits.length === 0)
          throw new Error("Please enter at least one subreddit to monitor");

        let redditQuery = convertToRedditQuery();

        await axios.patch(
          `${environment.BACKEND}/resources/job/${job._id}`,
          {
            jobName: job.jobName,
            q: redditQuery,
            timeFilter: job.timeFilter,
            durationSpan: job.durationSpan,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user?.accessToken}`,
            },
          }
        );
      }

      toast.success(`Job updated successfully`, {
        position: "top-right",
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
    } catch (err) {
      toast.error(`${err?.response?.data?.message ?? err.message}`, {
        position: "top-right",
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
    }
  };

  return (
    <div className="container job-detail mx-auto py-8 w-3/4">
      <h1 className="text-3xl font-bold mb-4">Lead Matcher Details</h1>
      <div className="mb-4">
        <label htmlFor="jobName" className="text-gray-600">
          Lead Matcher Name:
        </label>
        <div className="flex items-center w-full mt-2">
          <div className="flex items-center ml-2 w-full">
            {jobType === "linkedin" && (
              <FontAwesomeIcon
                icon={faLinkedin}
                style={{ color: "#0077b5", fontSize: "32px" }}
              />
            )}
            {jobType === "reddit" && (
              <FontAwesomeIcon
                icon={faReddit}
                style={{ color: " #ff4500", fontSize: "32px" }}
              />
            )}
            <input
              type="text"
              id="jobName"
              value={job.jobName}
              onChange={(e) => handleJobNameChange(e)}
              className="border border-gray-300 rounded-md px-4 py-2 w-full  ml-2"
            />
          </div>
        </div>
      </div>
      <div className="mb-4">
        <label htmlFor="query" className="text-gray-600">
          Keywords:
          <br />
          <span className="text-sm text-indigo-500">
            Phrases or words that my &quot;potential customers&quot; would be
            using
          </span>
        </label>
        <InputChipComponent
          keywords={keywords}
          setKeywords={setKeywords}
          placeholder="need website, looking for agency, help needed with seo"
        />
        <div className="flex justify-end">
          {jobType === "reddit" && (
            <span
              className={`${
                convertToRedditQuery().length - 9 > 500
                  ? "text-red-600"
                  : "text-gray-600"
              }`}
            >
              {convertToRedditQuery().length - 9} / 500{" "}
              <Tooltip
                text="Consider creating another Lead Matcher to support more keywords and subreddit"
                direction="left"
              >
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="ml-2 text-gray-500"
                />
              </Tooltip>
            </span>
          )}
          {jobType === "reddit" && (
            <span
              className={`${
                convertToRedditQuery().length - 9 > 500
                  ? "text-red-600"
                  : "text-gray-600"
              }`}
            >
              {convertToRedditQuery().length - 9} / 500{" "}
              <Tooltip
                text="Consider creating another Lead Matcher to support more keywords and subreddit"
                direction="left"
              >
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="ml-2 text-gray-500"
                />
              </Tooltip>
            </span>
          )}
        </div>
      </div>
      {jobType === "reddit" && (
        <div className="mb-4">
          <label htmlFor="query" className="text-gray-600">
            Subreddits to look in:
            <br />
            <span className="text-sm text-indigo-500">
              Subreddits where my customers are talking in
            </span>
          </label>
          <InputChipComponent
            keywords={subReddits}
            setKeywords={setSubreddits}
            placeholder="startups, entrepreneur, indiebiz, sideproject"
          />
        </div>
      )}

      <div className="mb-4">
        <label htmlFor="intervalHours" className="text-gray-600">
          Interval (hours):
        </label>
        <input
          type="number"
          id="intervalHours"
          value={job.durationSpan}
          onChange={(e) => handleDurationChange(e)}
          min="0"
          max="24"
          className="border border-gray-300 rounded-md px-4 py-2 w-full"
        />
      </div>
      {jobType === "reddit" && (
        <div className="mb-4">
          <label htmlFor="timeFilter" className="text-gray-600">
            Reddit Time Filter:
          </label>
          <ReactSelect
            id="timeFilter"
            options={[
              // { value: "hour", label: "HOUR" },
              { value: "day", label: "DAY" },
              { value: "week", label: "WEEK" },
              { value: "year", label: "YEAR" },
              // { value: "all", label: "ALL" },
            ]}
            value={{
              value: job.timeFilter,
              label: job.timeFilter.toLocaleUpperCase(),
            }}
            classNamePrefix="react-select"
            className="react-select-container"
            isSearchable={false}
            onChange={(selectedOption) =>
              handleTimeFilterChange(selectedOption.value)
            }
          />
        </div>
      )}
      {jobType === "linkedin" && (
        <>
          <div className="mb-4">
            <label htmlFor="datePosted" className="text-gray-600">
              Date Posted:
            </label>
            <select
              id="datePosted"
              value={linkedInTimeFilter}
              className="border border-gray-300 rounded-md px-4 py-2 w-full"
              onChange={(e) => handleLinkedinTimeFilterChange(e.target.value)}
            >
              <option value="day">Past 24 hours</option>
              <option value="week">Past week</option>
              <option value="month">Past month</option>
            </select>
          </div>
        </>
      )}

      <button
        className="bg-gray-700 hover:bg-gray-800 text-white py-2 px-4 rounded-md"
        onClick={handleUpdateJob}
      >
        Update & Rerun
      </button>

      <h2 className="text-xl font-bold mb-4 mt-10">Executions</h2>
      {job.executions.length === 0 ? (
        <h2 className="text-xl font-bold mb-4 text-gray-500 flex justify-content items-center justify-center">
          No executions found
        </h2>
      ) : (
        job.executions.map((execution) => {
          const executionTime = new Date(execution.createdAt);
          const currentTime = new Date();
          const timeDifference = Math.floor(
            (currentTime - executionTime) / (1000 * 60)
          ); // Time difference in minutes
          let hours = 0;

          let timeLabelClass = "bg-gray-200";
          let timeTextClass = "text-gray-500";

          let timeLabel = "";
          if (timeDifference < 60) timeLabel = `${timeDifference} minutes ago`;
          else {
            hours = Math.floor(timeDifference / 60);
            timeLabel = `${hours} ${hours === 1 ? "hour" : "hours"} ago`;
          }

          if (hours <= 6) {
            timeLabelClass = "bg-green-400";
            timeTextClass = "text-white";
          }

          const executionDate = executionTime.toLocaleDateString([], {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          });

          return (
            <div
              key={execution._id}
              className="border border-gray-300 rounded-md p-4 mb-4"
            >
              <div
                className={`inline-flex ${timeLabelClass} items-center mb-2 pl-1 pr-2 pt-1 pb-1 rounded-md`}
              >
                <div className="text-gray-800 rounded-md px-2 py-1">
                  <FaClock className={`${timeTextClass}`} />
                </div>
                <p className={`${timeTextClass}`}>{timeLabel}</p>
              </div>
              <p className="text-gray-600">Executed At: {executionDate}</p>
              <p className="text-gray-600">
                Posts Found: {execution.posts.length}
              </p>
              <NavLink to={`/execution-detail?execution_id=${execution._id}`}>
                <button className="bg-gray-700 hover:bg-gray-800 text-white py-2 px-4 rounded-md mt-4">
                  View Execution Details
                </button>
              </NavLink>
            </div>
          );
        })
      )}
    </div>
  );
}

export default JobDetails;
