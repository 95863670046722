import React from "react";

const PrivacyPolicy = () => {
  document.title = `Privacy Policy | LeadZLab`;

  return (
    <div className="bg-gray-800 text-gray-200 p-6 h-screen">
      <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>
      <p>
        At LeadZLab, we are committed to protecting your privacy. This Privacy
        Policy explains how we collect, use, and disclose information when you
        use our services. By using our services, you agree to the collection and
        use of your information as described in this Privacy Policy.
      </p>
      <h2 className="text-xl font-bold mt-6 mb-2">
        1. Collection of Information
      </h2>
      <p>
        We may collect personal information from you, such as your name, email
        address, and other contact information when you sign up for our
        services. We may also collect non-personal information, such as usage
        data, to improve and optimize our services.
      </p>
      <h2 className="text-xl font-bold mt-6 mb-2">2. Use of Information</h2>
      <p>
        We use the collected information to provide and improve our services,
        respond to your inquiries, and communicate with you about our products
        and updates. We may also use the information to run automated processes
        on behalf of the user, as authorized by the user.
      </p>
      <h2 className="text-xl font-bold mt-6 mb-2">
        3. Disclosure of Information
      </h2>
      <p>
        We may disclose your personal information to trusted third-party service
        providers who assist us in operating our services, conducting business,
        or servicing you. We may also disclose your information as required by
        law or to protect our rights, property, or safety.
      </p>
      <h2 className="text-xl font-bold mt-6 mb-2">4. Security</h2>
      <p>
        We take reasonable measures to protect the security of your information.
        However, please be aware that no data transmission over the internet or
        storage method is 100% secure. We cannot guarantee the absolute security
        of your information.
      </p>
      <h2 className="text-xl font-bold mt-6 mb-2">5. Your Choices</h2>
      <p>
        You have the right to access, update, and delete your personal
        information. You can also opt-out of receiving promotional emails from
        us. Please contact us using the provided contact details to exercise
        your rights.
      </p>
      <h2 className="text-xl font-bold mt-6 mb-2">
        6. Updates to Privacy Policy
      </h2>
      <p>
        We may update this Privacy Policy from time to time. Any changes will be
        effective immediately upon posting the updated Privacy Policy on our
        website. It&apos;s your responsibility to review the Privacy Policy
        periodically for any updates.
      </p>
      <p className="mt-6">
        If you have any questions or concerns about our Privacy Policy, please
        contact us using the provided contact details.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
