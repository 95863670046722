import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import SectionTitle from "./components/SectionTitle";

import { benefitOne, benefitTwo } from "./components/data";
import Video from "./components/Video";
import Benefits from "./components/Benefits";
import Footer from "./components/Footer";
import UseCases from "./components/UseCases";
import Cta from "./components/CTA";
import Faq from "./components/FAQ";
// import PopupWidget from "../components/popupWidget";

const Home = () => {
  window.location.href = "https://leadzlab.co";
  return (
    <>
      <Navbar />
      <Hero />
      <span id="demo" />
      <SectionTitle
        pretitle="Watch a video"
        title="How does it work?"
      ></SectionTitle>
      <Video />
      <SectionTitle
        pretitle="LeadZLab Benefits"
        title="Discover Meaningful Interactions: Embrace Growth through Conversations"
      >
        As a founder, it&apos;s easy to get lost in a sea of tasks that yield
        little progress. Many opt to retreat and focus solely on product
        development, missing out on valuable market insights and challenges.
        <b>
          {" "}
          LeadZLab was born out of the need to actively engage in relevant
          conversations, learn from others struggles, and provide effective
          solutions.
        </b>
      </SectionTitle>
      <Benefits data={benefitOne} />
      <Benefits imgPos="right" data={benefitTwo} />
      <span id="use-cases"> </span>

      <SectionTitle
        pretitle="Use cases"
        title="Here's how LeadZLab can help you"
      >
        Reddit has ALL types of data. LeadZLab is how you can generate a revenue
        out of it. LeadZLab offers endless possibilities for utilization, but
        here are a few examples.
      </SectionTitle>
      <UseCases />

      {/* <SectionTitle
        pretitle="Testimonials"
        title="Here's what our customers said"
      >
        Testimonails is a great way to increase the brand trust and awareness.
        Use this section to highlight your popular customers.
      </SectionTitle>
      <Testimonials /> */}
      <SectionTitle pretitle="FAQ" title="Frequently Asked Questions">
        {/* Answer your customers possible questions here, it will increase the
        conversion rate as well as support or chat requests. */}
      </SectionTitle>
      <Faq />
      <Cta />
      <Footer />
      {/* <PopupWidget /> */}
    </>
  );
};

export default Home;
