import * as React from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Signup from "./pages/Signup/Signup";
import RoleRoutes from "./rbac/RoleRoutes";
import Login from "./pages/Login/Login";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import LandingPage from "./pages/LandingPage/LandingPage";
import OldLandingPage from "./pages/LandingPage/archive/LandingPage.old";
import TermsOfService from "./pages/TermsOfService/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import ContactUs from "./pages/ContactUs/ContactUs";

function App() {
  //TODO: 10. Deployment + Domain name
  //TODO: 7. User plan structure.
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/old-landing-page" element={<OldLandingPage />} />
        <Route path="/terms-of-service" element={<TermsOfService />}></Route>
        <Route path="/privacy-policy" element={<PrivacyPolicy />}></Route>
        <Route path="/contact-us" element={<ContactUs />}></Route>
        <Route path="/signup" element={<Signup />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/forgot-password" element={<ForgotPassword />}></Route>
        <Route path="/change-password" element={<ChangePassword />}></Route>
        <Route path="/*" element={<RoleRoutes />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
