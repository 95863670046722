import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

// eslint-disable-next-line react/prop-types
const LinkComponent = ({ link, name, active, icon }) => {
  useEffect(() => {}, [active]);

  return (
    <NavLink to={link} className="sidebar-anchor">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          verticalAlign: "middle",
        }}
      >
        <span>{icon}</span>

        <span
          style={{
            marginLeft: "5px",
            fontWeight: "600",
            fontSize: "16px",
          }}
        >
          {name}
        </span>
      </div>
    </NavLink>
  );
};
export default LinkComponent;
