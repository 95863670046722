import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

import axios from "axios";
import { environment } from "../../environments/environment";
import { useAuthContext } from "../../hooks/useAuthContext";

const PaymentRedirectPage = () => {
  const { user } = useAuthContext();
  const [paymentStatus, setPaymentStatus] = useState("");
  const [nextPaymentDate, setNextPaymentDate] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();

  const upgradePlan = async () => {
    try {
      const success = searchParams.get("success");
      const cancelled = searchParams.get("cancelled");

      if (success === "true") {
        const session_id = searchParams.get("session_id");
        if (!session_id) {
          setPaymentStatus("cancelled");
          return;
        }

        let { data } = await axios.post(
          `${environment.BACKEND}/user/payment/upgrade-plan`,
          {
            session_id,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user?.accessToken}`,
            },
          }
        );

        console.log(data);

        setPaymentStatus("success");
        const currentDate = new Date();
        const nextMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth() + 1,
          currentDate.getDate()
        );
        setNextPaymentDate(nextMonth.toLocaleDateString());
      } else if (cancelled === "true") {
        setPaymentStatus("cancelled");
      } else {
        setPaymentStatus("unknown");
      }
    } catch (err) {
      setPaymentStatus("cancelled");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    upgradePlan();
  }, []);

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      {isLoading ? (
        <div className="flex items-center space-x-2 text-gray-600">
          <AiOutlineLoading3Quarters className="animate-spin" />
          <span>Processing your payment...</span>
        </div>
      ) : (
        <>
          {paymentStatus === "success" && (
            <div className="bg-white rounded-lg shadow-md p-8">
              <h2 className="text-xl font-semibold mb-4">Payment Successful</h2>
              <p>
                Congratulations! You have successfully upgraded your plan.{" "}
                <br /> Your next payment is due on {nextPaymentDate}.
              </p>
            </div>
          )}

          {paymentStatus === "cancelled" && (
            <div className="bg-white rounded-lg shadow-md p-8">
              <h2 className="text-xl font-semibold mb-4">Payment Failed</h2>
              <p>
                Payment has failed. Please try again later or contact support at{" "}
                <a
                  href="mailto:support@leadzlab.co"
                  className="text-blue-500 underline"
                >
                  support@leadzlab.co
                </a>
                .
              </p>
            </div>
          )}

          {paymentStatus === "unknown" && (
            <div className="bg-white rounded-lg shadow-md p-8">
              <h2 className="text-xl font-semibold mb-4">
                Oops! Something went wrong
              </h2>
              <p>
                We couldn&apos;t process your payment. Please try again later or
                contact support at{" "}
                <a
                  href="mailto:support@leadzlab.co"
                  className="text-blue-500 underline"
                >
                  support@leadzlab.co
                </a>
                .
              </p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PaymentRedirectPage;
