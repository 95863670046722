// * Package Imports
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { environment } from "../../environments/environment";
import { useAuthContext } from "../../hooks/useAuthContext";
import EmailIcon from "@mui/icons-material/Email";

const AlertsPage = () => {
  document.title = `Alerts | LeadZLab`;

  const [isLoading, setIsLoading] = useState(true);
  const [createAlertLoading, setCreateAlertLoading] = useState(false);

  const [alerts, setAlerts] = useState([]);
  const [newAlertEmail, setNewAlertEmail] = useState("");
  const MAX_ALERTS = 2;

  const { user } = useAuthContext();

  // * Fetch alerts
  const getAlerts = async () => {
    try {
      setIsLoading(true);
      let { data } = await axios.get(`${environment.BACKEND}/alerts`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.accessToken}`,
        },
      });
      setAlerts(data.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err.response.data);
      if (err.response?.status === 400) window.alert("Please login again");
      setIsLoading(false);
      toast.error(`${err?.response?.data?.msg ?? err.message}`, {
        position: "top-right",
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
    }
  };

  useEffect(() => {
    getAlerts();
  }, []);

  const handleAddAlert = async () => {
    try {
      setCreateAlertLoading(true);

      let { data } = await axios.post(
        `${environment.BACKEND}/alerts`,
        {
          email: newAlertEmail,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.accessToken}`,
          },
        }
      );
      toast.success(data.msg, {
        position: "top-right",
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
      getAlerts();
      setNewAlertEmail("");
      setCreateAlertLoading(false);
    } catch (err) {
      // console.log(err.response.data);
      // if (err.response?.status === 400) window.alert("Please login again");
      toast.error(`${err?.response?.data?.msg ?? err.message}`, {
        position: "top-right",
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
      setCreateAlertLoading(false);
    }
  };

  const handleDeleteAlert = async (id) => {
    try {
      let { data } = await axios.delete(`${environment.BACKEND}/alerts/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.accessToken}`,
        },
      });
      toast.success(data.msg, {
        position: "top-right",
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
      getAlerts();
    } catch (err) {
      toast.error(`${err?.response?.data?.msg ?? err.message}`, {
        position: "top-right",
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        {/* Skeleton loader */}
        <div className="animate-pulse bg-gray-200 rounded-lg p-4 w-64"></div>
      </div>
    );
  }

  return (
    <div className="container ml-0 p-4 mt-[50px] w-4/5 lg:ml-[210px]">
      <h1 className="text-2xl font-bold">Alerts</h1>
      <p className="text-gray-600 mb-4">
        You should expect about 1-2 mails every day
      </p>
      {/* Limit the number of alerts */}
      {alerts.length < MAX_ALERTS ? (
        <div className="mb-4">
          <input
            type="text"
            value={newAlertEmail}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleAddAlert();
              }
            }}
            onChange={(e) => setNewAlertEmail(e.target.value)}
            className="border border-gray-200 rounded-lg px-4 py-2 mr-2 w-2/5"
            placeholder="Enter email for new alert"
          />
          <button
            onClick={handleAddAlert}
            disabled={createAlertLoading}
            className={`px-4 py-2 bg-indigo-500 text-white rounded-lg ${
              createAlertLoading ? "bg-indigo-400" : ""
            }`}
          >
            Add Alert
          </button>
        </div>
      ) : (
        <div className="mb-4">
          <p className="text-red-500">You cannot add more than 2 alerts.</p>
        </div>
      )}
      {alerts.length === 0 ? (
        <div className="p-8 border border-gray-200 rounded-lg text-center">
          <p className="text-lg text-gray-900">
            <strong>No alerts set up.</strong>
          </p>
          <p className="text-gray-600">
            Add multiple emails to stay on top of your Lead Matchers!
          </p>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {alerts.map((alert) => (
            <div
              key={alert._id}
              className={`border border-gray-200 rounded-lg p-4 ${
                alert.active ? "bg-indigo-100" : ""
              }`}
            >
              <div className="flex items-center">
                <EmailIcon className="text-gray-700 mr-2" />
                <p className="text-gray-700">
                  <strong>Email:</strong> {alert.email}
                </p>
              </div>
              <button
                onClick={() => handleDeleteAlert(alert._id)}
                className="mt-2 px-4 py-2 bg-red-500 text-white rounded-lg"
              >
                Delete
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AlertsPage;
