import React from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

const FixedBanner = ({ icon, message, actionText, actionLink, color }) => {
  return (
    <NavLink
      to={actionLink}
      className={`top bottom-0 left-0 w-full bg-${color}-500 text-white p-2 flex items-center justify-between rounded-lg mb-4`}
    >
      <div className="flex items-center">
        <span className="mr-2">{icon}</span>
        <p>{message}</p>
      </div>
      {actionText && (
        <NavLink to={actionLink} className="text-white hover:underline">
          {actionText}
        </NavLink>
      )}
    </NavLink>
  );
};

FixedBanner.propTypes = {
  icon: PropTypes.node,
  message: PropTypes.string.isRequired,
  actionText: PropTypes.string,
  actionLink: PropTypes.string,
  color: PropTypes.string.isRequired,
};

export default FixedBanner;
