import React, { useState } from "react";
import "./VideoPlayer.css";

const VideoPlayer = () => {
  const [playVideo, setPlayVideo] = useState(true);

  const handleVideoClick = () => {
    setPlayVideo(true);
  };

  return (
    <div className="video-container">
      <div onClick={handleVideoClick} className="video-player">
        {!playVideo && (
          <div className="play-overlay">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="play-icon"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                clipRule="evenodd"
              />
            </svg>
            <span className="play-label">Play Video</span>
          </div>
        )}
        {playVideo && (
          <iframe
            src="https://www.youtube.com/embed/y5PsnWsWUBI?" //&autoplay=1"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="video-iframe"
          ></iframe>
        )}
      </div>
    </div>
  );
};

export default VideoPlayer;
