import React from "react";
import Logo from "../../assets/Logo(500x500).png";

const TermsOfServicePage = () => {
  document.title = `Terms | LeadZLab`;

  return (
    <div className="flex flex-col items-center min-h-screen bg-indigo-50 pt-16">
      <div className="py-1 rounded px-2 flex justify-center items-center">
        <img src={Logo} width={"80"} alt="logo" className="rounded-lg" />
      </div>
      <div className="bg-white max-w-3xl px-8 py-6 rounded-lg shadow-lg mt-8">
        <h1 className="text-2xl font-bold mb-4">Terms of Service</h1>
        <p className="mb-4">
          Welcome to LeadZLab. Please read these terms and conditions carefully
          before using our services. By accessing or using our product, you
          agree to be bound by these terms of service. If you do not agree to
          these Terms, please refrain from using the Service.
        </p>
        <h2 className="text-lg font-bold mb-2">1. Acceptance of Terms</h2>
        <p className="mb-4">
          By using our product, you acknowledge that you have read, understood,
          and agree to comply with these terms of service. If you do not agree
          with any part of these terms, please do not use our product.
        </p>
        <h2 className="text-lg font-bold mb-2">2. Misuse of the System</h2>
        <p className="mb-4">
          We reserve the right to terminate or suspend any account that we
          believe, in our sole discretion, is misusing the system, violating
          these Terms, or engaging in any activity that may harm the Service or
          its users.
        </p>
        <h2 className="text-lg font-bold mb-2">3. Prohibited Activities</h2>
        <p className="mb-4">
          You agree not to use our product for any illegal, unauthorized, or
          prohibited activities. This includes, but is not limited to:
        </p>
        <ul className="list-disc ml-6 mb-4">
          <li>
            Misusing or abusing the features and functionalities of our product.
          </li>
          <li>
            Violating any applicable laws, regulations, or third-party rights.
          </li>
          <li>
            Attempting to gain unauthorized access to our product or its related
            systems.
          </li>
          <li>
            Engaging in any activity that may disrupt or interfere with the
            proper functioning of our product.
          </li>
        </ul>
        <h2 className="text-lg font-bold mb-2">4. Intellectual Property</h2>
        <p className="mb-4">
          All intellectual property rights, including but not limited to
          copyrights, trademarks, and patents, related to our product are owned
          by us or our licensors. You agree not to use, modify, reproduce, or
          distribute any of our intellectual property without prior written
          consent.
        </p>
        <h2 className="text-lg font-bold mb-2">5. Disclaimer of Warranty</h2>
        <p className="mb-4">
          Our product is provided on an &quot;as is&quot; and &quot;as
          available&quot; basis, without any warranties or representations,
          express or implied. We do not guarantee the accuracy, reliability, or
          completeness of any information provided by our product. You use our
          product at your own risk.
        </p>
        <h2 className="text-lg font-bold mb-2">6. Limitation of Liability</h2>
        <p className="mb-4">
          We shall not be liable for any direct, indirect, incidental, special,
          or consequential damages arising out of or in any way connected with
          the use of our product, even if we have been advised of the
          possibility of such damages. Your sole remedy for any dissatisfaction
          with our product is to discontinue using it.
        </p>
        <h2 className="text-lg font-bold mb-2">7. Governing Law</h2>
        <p className="mb-4">
          These terms of service shall be governed by and construed in
          accordance with the laws of India. Any disputes arising out of or
          relating to these terms shall be subject to the exclusive jurisdiction
          of the courts in India.
        </p>
        <h2 className="text-lg font-bold mb-2">8. Refund Policy</h2>
        <p className="mb-4">
          We offer a 3-day money-back guarantee. If you are not satisfied with
          our service, please contact our support team within 3 days of your
          subscription purchase for a full refund.
        </p>
        <h2 className="text-lg font-bold mb-2">9. Payment Information</h2>
        <p className="mb-4">
          To subscribe to the Service, you may be required to provide payment
          information. You agree to provide accurate and complete payment
          information and authorize us to charge the applicable fees to your
          chosen payment method.
        </p>
        <h2 className="text-lg font-bold mb-2">10. Updates and Amendments</h2>
        <p className="mb-4">
          LeadZLab reserves the right to update or amend these terms of service
          from time to time to reflect changes to our business practices or for
          other operational, legal, or regulatory reasons. Users are encouraged
          to review the terms of service regularly for any changes. Continued
          use of our product after any such changes shall constitute your
          consent to such changes.{" "}
        </p>
      </div>
    </div>
  );
};

export default TermsOfServicePage;
