import React, { useState, useRef } from "react";
import PropTypes from "prop-types";

const InputComponent = ({ keywords, setKeywords, placeholder }) => {
  const [inputValue, setInputValue] = useState("");
  const inputRef = useRef();

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyPress = (e) => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      if (inputValue.trim() !== "") {
        setKeywords((prevKeywords) => [...prevKeywords, inputValue.trim()]);
        setInputValue("");
      }
    }
  };

  const removeKeyword = (keyword) => {
    setKeywords((prevKeywords) => prevKeywords.filter((kw) => kw !== keyword));
  };

  const handleBackspace = (e) => {
    if (e.key === "Backspace" && inputValue === "") {
      setKeywords(keywords.slice(0, keywords.length - 1));
    }
  };

  const handleInputPaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain");
    const parts = pastedData
      .split(/\s*\bor\b\s*|\s*,\s*/i)
      .filter((part) => part.trim() !== "");
    setKeywords((prevKeywords) => [...prevKeywords, ...parts]);
  };

  return (
    <div className="flex flex-wrap border rounded-md p-2">
      {keywords.map((keyword, index) => (
        <div key={index} className="inline-flex items-center">
          <span className="inline-flex items-center bg-indigo-500 text-white px-2 py-1 m-1 rounded-full">
            {keyword}
            <button
              className="ml-1  focus:outline-none select-none"
              onClick={() => removeKeyword(keyword)}
              aria-label="Remove"
            >
              &#10005;
            </button>
          </span>
          <span className="text-gray-700">or</span>
        </div>
      ))}
      <input
        type="text"
        ref={inputRef}
        onKeyDown={handleBackspace}
        className="flex-1 ml-2  focus:outline-none"
        placeholder={placeholder}
        value={inputValue}
        onChange={handleInputChange}
        onPaste={handleInputPaste}
        onKeyPress={handleInputKeyPress}
      />
    </div>
  );
};

InputComponent.propTypes = {
  keywords: PropTypes.arrayOf(PropTypes.string).isRequired,
  setKeywords: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
};

export default InputComponent;
