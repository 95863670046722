/* eslint-disable no-unused-vars */
import React from "react";
import Container from "./Container";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/24/solid";

const Faq = () => {
  return (
    <Container className="!p-0">
      <div className="w-full max-w-2xl p-2 mx-auto rounded-2xl">
        {faqdata.map((item, index) => (
          <div key={item.question} className="mb-5">
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex items-center justify-between w-full px-4 py-4 text-lg text-left text-gray-800 rounded-lg bg-gray-50 hover:bg-gray-100 focus:outline-none focus-visible:ring focus-visible:ring-indigo-100 focus-visible:ring-opacity-75">
                    <span>{item.question}</span>
                    <ChevronUpIcon
                      className={`${
                        open ? "transform rotate-180" : ""
                      } w-5 h-5 text-indigo-500`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="px-4 pt-4 pb-2 text-gray-500">
                    {item.answer}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        ))}
      </div>
    </Container>
  );
};

const faqdata = [
  {
    question: "Is this tool completely free to use?",
    answer:
      "LeadZLab is currently in beta. We will soon be launching different pricing plans. Until then it is completely free to use",
  },
  {
    question: "I have some suggestions",
    answer:
      "We welcome suggestion and feature requests! Please drop us an email at support@leadzlab.co and we will get back to you soon!",
  },
  {
    question: "Ugh! I am confused! How do I set this up?",
    answer:
      "Drop us an email at support@leadzlab.co and lets help you get your leads now!",
  },
  // {
  //   question: "Do you offer technical support? ",
  //   answer:
  //     "No, we don't offer technical support for free downloads. Please purchase a support plan to get 6 months of support.",
  // },
];

export default Faq;
