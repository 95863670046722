/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faReddit } from "@fortawesome/free-brands-svg-icons";

// * Component imports
import FixedBanner from "../../components/FixedBanner/FixedBanner";
import WarningIcon from "@mui/icons-material/Warning";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import BoltIcon from "@mui/icons-material/Bolt";

// * Environment Imports
import { environment } from "../../environments/environment";

// * Import styles
import "./Dashboard.css";

// * Axios Imports
import axios from "axios";

// * Import Context
import { useAuthContext } from "../../hooks/useAuthContext";

function Dashboard() {
  document.title = `Dashboard | LeadZLab`;

  // * Plan details
  const [plan, setPlan] = useState("");
  const [trialValidUntil, setTrialValidUntil] = useState(false);

  // * Nudges
  const [quota, setQuota] = useState({});

  // * Recent jobs
  const [jobs, setJobs] = useState([]);
  const [jobLoader, setJobLoader] = useState(true);

  // * Recent executions
  const [executions, setExecutions] = useState([]);
  const [executionLoader, setExecutionLoader] = useState(true);

  // * Auth context
  const { user } = useAuthContext();

  // * Fetch jobs
  const getJobs = async () => {
    try {
      let { data } = await axios.get(
        `${environment.BACKEND}/resources/job?dashboard=true`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.accessToken}`,
          },
        }
      );

      setJobs(data.data);
      setJobLoader(false);
    } catch (err) {
      console.log(err.response.data);
      if (err.response?.status === 400) window.alert("Please login again");
    }
  };

  // * Fetch executions
  const getExecutions = async () => {
    try {
      let { data } = await axios.get(
        `${environment.BACKEND}/resources/executions?dashboard=true`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.accessToken}`,
          },
        }
      );
      setExecutions(data.data);
      setExecutionLoader(false);
    } catch (err) {
      console.log(err.response.data);
    }
  };

  function calculateDateDifference(timestamp) {
    const now = new Date();
    const targetDate = new Date(timestamp);

    // Check if the target date is in the past
    if (targetDate < now) {
      return -1;
    }

    const timeDifference = targetDate - now;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    return daysDifference;
  }

  // * Fetch plan
  const getPlan = async () => {
    try {
      const { data } = await axios.get(`${environment.BACKEND}/user`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.accessToken}`,
        },
      });
      const userData = data.data;
      setPlan(userData.plan);

      setTrialValidUntil(
        calculateDateDifference(userData.subscriptionActiveTill)
      );
    } catch (err) {
      console.log(err.response.data);
    }
  };

  // * Check Quota
  const checkQuota = async () => {
    try {
      const { data } = await axios.get(`${environment.BACKEND}/user/quota`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.accessToken}`,
        },
      });
      setQuota(data.data);
    } catch (err) {
      console.log(err.response.data);
    }
  };

  useEffect(() => {
    getJobs();
    getExecutions();
    getPlan();
    checkQuota();
  }, []);

  return (
    <div className="container dashboard w-4/5">
      {plan === "free" ? (
        trialValidUntil < 0 ? (
          <FixedBanner
            icon={<WarningIcon />}
            message={`Your free trial has expired. Please upgrade now to continue generating leads`}
            actionText="Upgrade"
            actionLink="/pricing"
            color="red"
          />
        ) : (
          <FixedBanner
            icon={<BoltIcon />}
            message={`Your free trial expires in ${trialValidUntil} days`}
            actionText="Upgrade"
            actionLink="/pricing"
            color="indigo"
          />
        )
      ) : plan === "" ? null : (
        <FixedBanner
          icon={<VerifiedUserIcon />}
          message={`You are currently on the ${plan?.toUpperCase()} plan`}
          // actionText="Upgrade"
          // actionLink="/pricing"
          color="yellow"
        />
      )}

      {/* Job limit nudge */}
      {quota.jobsCreated >= quota.jobsAllowed ? (
        <FixedBanner
          icon={<PriorityHighIcon />}
          message={`You will not be able to create any more lead matchers. Upgrade plan to create more. (${quota.jobsCreated}/${quota.jobsAllowed})`}
          actionText="Upgrade"
          actionLink="/pricing"
          color="gray"
        />
      ) : null}

      {/* Execution limit nudge */}
      {quota.executionsCreated >= quota.executionsAllowed ? (
        <FixedBanner
          icon={<PriorityHighIcon />}
          message={`Lead matchers have paused for today. Wait 24 hours for fresh leads, or upgrade plan. (${quota.executionsCreated}/${quota.executionsAllowed})`}
          actionText="Upgrade"
          actionLink="/pricing"
          color="gray"
        />
      ) : null}

      <h1 className="text-3xl font-bold mb-4">Recent lead matchers</h1>
      {!jobLoader || <Skeleton count={5} />}
      {jobs.length == 0 && !jobLoader ? (
        <div
          className="flex justify-center items-center"
          style={{ height: "200px" }}
        >
          <div>
            <p className="text-xl text-gray-600 text-center font-bold">
              <NavLink
                to="/create-job"
                className="text-blue-500 hover:text-blue-600 underline"
              >
                Setup Reddit Lead Matcher
              </NavLink>
            </p>
          </div>
        </div>
      ) : (
        <div className="bg-white rounded-lg shadow">
          <table className="w-full">
            <thead>
              <tr>
                <th className="py-2 px-4 bg-gray-100">Lead Matcher Name</th>
                <th className="py-2 px-4 bg-gray-100">Type</th>
                <th className="py-2 px-4 bg-gray-100">Last Run At</th>
                <th className="py-2 px-4 bg-gray-100">Next Run At</th>
                <th className="py-2 px-4 bg-gray-100">View</th>
              </tr>
            </thead>
            <tbody>
              {jobs.map((job, index) => (
                <tr
                  key={job._id}
                  className={`${index % 2 === 0 ? "white" : "bg-gray-100"} ${
                    job.is_processing ? "bg-yellow-100 text-gray-400" : ""
                  }`}
                >
                  <td className="py-2 px-4 text-center">{job.jobName}</td>
                  <td className="py-2 px-4 text-center">
                    {job.type === "linkedin" && (
                      <FontAwesomeIcon
                        icon={faLinkedin}
                        style={{ color: "#0077b5", fontSize: "32px" }}
                      />
                    )}
                    {job.type === "reddit" && (
                      <FontAwesomeIcon
                        icon={faReddit}
                        style={{ color: " #ff4500", fontSize: "30px" }}
                      />
                    )}
                  </td>
                  <td className="py-2 px-4 text-center">
                    {" "}
                    {job.lastExecuted !== "1970-01-01T00:00:00.000Z"
                      ? new Date(job.lastExecuted).toLocaleString([], {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        })
                      : "--"}
                  </td>
                  <td className="py-2 px-4 text-center">
                    {" "}
                    {job.is_processing
                      ? "Processing... (This may take 1-2 minutes)"
                      : job.nextRun !== "1970-01-01T00:00:00.000Z"
                      ? new Date(job.nextRun).toLocaleString([], {
                          year: "numeric",
                          month: "short",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                          hour12: true,
                        })
                      : "In a minute"}
                  </td>
                  <td className="py-2 px-4 text-center">
                    <a href="#" className="text-blue-500 hover:text-blue-700">
                      <NavLink to={`/job-detail?job_id=${job._id}`}>
                        View
                      </NavLink>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <br />
      <br />
      {!executionLoader || <Skeleton count={5} />}
      {executions.length == 0 ? null : (
        <>
          <h1 className="text-3xl font-bold my-4">Recent Executions</h1>
          <div className="bg-white rounded-lg shadow">
            <table className="w-full">
              <thead>
                <tr>
                  <th className="py-2 px-4 bg-gray-100">Executed At</th>
                  <th className="py-2 px-4 bg-gray-100">Type</th>
                  <th className="py-2 px-4 bg-gray-100">Posts Found</th>
                  <th className="py-2 px-4 bg-gray-100">Lead Matcher Name</th>
                  <th className="py-2 px-4 bg-gray-100">View</th>
                </tr>
              </thead>
              <tbody>
                {executions.map((exec, index) => (
                  <tr
                    key={exec._id}
                    className={`${index % 2 === 0 ? "white" : "bg-gray-100"}`}
                  >
                    <td className="py-2 px-4 text-center">
                      {new Date(exec.createdAt).toLocaleString([], {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      })}
                    </td>
                    <td className="py-2 px-4 text-center">
                      {exec.job_id.type === "linkedin" && (
                        <FontAwesomeIcon
                          icon={faLinkedin}
                          style={{ color: "#0077b5", fontSize: "32px" }}
                        />
                      )}
                      {exec.job_id.type === "reddit" && (
                        <FontAwesomeIcon
                          icon={faReddit}
                          style={{ color: " #ff4500", fontSize: "30px" }}
                        />
                      )}
                    </td>
                    <td className="py-2 px-4 text-center">
                      {exec.posts.length}
                    </td>
                    <td className="py-2 px-4 text-center">
                      {exec.job_id.jobName}
                    </td>
                    <td className="py-2 px-4 text-center">
                      <a href="#" className="text-blue-500 hover:text-blue-700">
                        <NavLink
                          to={`/execution-detail?execution_id=${exec._id}`}
                        >
                          View
                        </NavLink>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
}

export default Dashboard;
