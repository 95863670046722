// * React Import
import { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../../assets/Logo(500x500).png";
import { ThreeDots } from "react-loader-spinner";
import "./Signup.css";

// * Environment Imports
import { environment } from "../../environments/environment";

// * Package Imports
import axios from "axios";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

// * Import Context
import { useAuthContext } from "../../hooks/useAuthContext";

export default function SignUp() {
  document.title = `Sign up | LeadZLab`;

  const { dispatch, user } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [terms, setTerms] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (user) navigate("/dashboard");
  }, [user]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!terms)
      return toast.error(
        `Please accept the terms of service and privacy policy to continue`,
        {
          position: "top-right",
          autoClose: true,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        }
      );

    setLoading(true);

    const formInput = new FormData(event.currentTarget);

    // * Sign up route
    try {
      let { data } = await axios.post(
        `${environment.BACKEND}/user/auth/signup`,
        {
          firstName: formInput.get("firstName"),
          lastName: formInput.get("lastName"),
          email: formInput.get("email"),
          redditUsername: formInput.get("redditUsername"),
          password: formInput.get("password"),
        },
        { headers: { "Content-Type": "application/json" } }
      );
      toast.success(`Sign up successful!`, {
        position: "top-right",
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
      Cookies.set("user", JSON.stringify(data.data), { expires: 365 });
      dispatch({ type: "LOGIN", payload: data.data });
      setLoading(false);
      navigate("/create-job");
    } catch (err) {
      toast.error(`${err?.response?.data?.message ?? err.message}`, {
        position: "top-right",
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
      setLoading(false);
    }
  };

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <div className="bg-indigo-100 h-screen w-screen flex items-center">
      <div className="mx-auto p-10 h-full bg-white rounded-md overflow-y-scroll shadow-lg w-screen lg:w-1/3 lg:h-5/6 scrollbar-none sign-up-scrollbar ">
        <div className="py-3 rounded px-6 w-full flex justify-content items-center justify-center">
          <img src={Logo} width={"80"} alt="logo" className="rounded-lg" />
        </div>
        <form className="px-6 py-2" onSubmit={(e) => handleSubmit(e)}>
          <div className="mb-5">
            <label className="block text-gray-700 font-bold mb-2">
              First name
            </label>
            <input
              className="appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="firstName"
              id="first_name"
              type="text"
              placeholder="Enter your first name"
            />
          </div>
          <div className="mb-5">
            <label className="block text-gray-700 font-bold mb-2">
              Last name
            </label>
            <input
              className="appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="lastName"
              id="last_name"
              type="text"
              placeholder="Enter your last name"
            />
          </div>
          <div className="mb-5">
            <label className="block text-gray-700 font-bold mb-2">
              Reddit username
            </label>
            <input
              className="appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="redditUsername"
              id="reddit_username"
              type="text"
              placeholder="Enter your Reddit username"
            />
          </div>
          <div className="mb-5">
            <label className="block text-gray-700 font-bold mb-2">Email</label>
            <input
              className="appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="email"
              id="email"
              type="email"
              placeholder="Enter your email address"
            />
          </div>
          <div className="mb-3">
            <label className="block text-gray-700 font-bold mb-2">
              Password
            </label>
            <div className="relative">
              <input
                className="appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="password"
                id="password"
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
              />
              <button
                type="button"
                className="absolute top-0 right-0 h-full w-10 text-gray-400 hover:text-gray-500 flex items-center justify-center"
                onClick={handleClickShowPassword}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="flex  lg:justify-center lg:items-center lg:text-center flex-wrap  text-gray-700 mb-4 justify-start items-center text-left">
            <input
              type="checkbox"
              className="h-4 w-4 accent-gray-700  bg-grey-700 text-red-500  rounded cursor-pointer mr-2"
              value={terms}
              onChange={(e) => setTerms(e.target.checked)}
            />{" "}
            I agree to the{" "}
            <a
              href="https://leadzlab.co/terms-of-service"
              target="__blank"
              className="ml-1 mr-1 underline text-blue-500"
            >
              Terms of Service
            </a>{" "}
            and{" "}
            <a
              href="https://leadzlab.co/privacy-policy"
              target="__blank"
              className="ml-1 mr-1 underline text-blue-500"
            >
              Privacy Policy
            </a>
          </div>

          <div className="flex items-center justify-center">
            <button
              className="inline-block px-4 py-2 text-white bg-indigo-600 hover:bg-indigo-500 rounded w-80 text-center focus:outline-none focus:shadow-outline flex items-center justify-center"
              type="submit"
              disabled={loading}
            >
              {loading ? (
                <ThreeDots
                  radius="6"
                  height={25}
                  color="#ffffff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              ) : (
                <>Sign up</>
              )}
            </button>
          </div>
          <div className="text-center mt-5">
            Already have an account?{" "}
            <NavLink to={"/login"} className="text-blue-500 font-bold">
              Login
            </NavLink>
          </div>
        </form>
      </div>
    </div>
  );
}
