/* eslint-disable react/prop-types */
import React from "react";
import "./Sidebar.css";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";

// * Import Context
import { useAuthContext } from "../../hooks/useAuthContext";

import LinkComponent from "./components/Link/Link";

// eslint-disable-next-line react/prop-types
function Sidebar({ PROTECTED_ROUTES }) {
  const { dispatch } = useAuthContext();

  const navSlide = () => {
    const burger = document.querySelector(".burger");
    const nav = document.querySelector(".nav");

    // * Toggle Nav
    nav.classList.toggle("nav-active");
    burger.classList.toggle("toggle");
  };

  // * Color highlighting
  // useEffect(() => {
  //   console.log(window.location.pathname);
  // }, [window.location.pathname]);

  return (
    <div id="sidebar-area" className="w-200">
      <div className="navBar">
        <span className="sidebar_dropdown"></span>
        <div className="burger" onClick={navSlide}>
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>
      </div>
      <aside
        className="nav sidebar bg-gray-800 text-white w-200"
        id="sidebar-container"
      >
        <div className="containerAside h-screen flex flex-col  justify-between">
          <div className="h-screen flex flex-col">
            <div id="sidebar_lead_z_lab_container">
              <Link to="/">LeadZLab</Link>
            </div>
            {PROTECTED_ROUTES.filter((route) => route.includedInSidebar).map(
              (route) => (
                <LinkComponent
                  link={route.link}
                  name={route.name}
                  icon={route.icon}
                  active={
                    location.pathname.split("/")[1] === route.link.slice(1)
                  }
                  key={route.key}
                />
              )
            )}
          </div>
          <div className="flex items-center justify-center mb-0">
            <div className="text-white">
              <div className="p-4">
                <button
                  className="bg-gray-800 hover:bg-gray-600 text-white py-2 px-4 rounded-md"
                  onClick={() => {
                    Cookies.remove("user");
                    dispatch("LOGOUT");
                    window.location.reload();
                  }}
                >
                  Logout
                </button>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </div>
  );
}

export default Sidebar;
