/* eslint-disable react/prop-types */
import React from "react";
import Container from "./Container";

import FreelanceImage from "../../../assets/freelance.svg";
import MarketResearchImage from "../../../assets/market-research.svg";
import SupportImage from "../../../assets/support.svg";

const Testimonials = () => {
  return (
    <Container>
      <div className="grid gap-10 lg:grid-cols-2 xl:grid-cols-3">
        <div className="lg:col-span-2 xl:col-auto">
          <div className="flex flex-col justify-between w-full h-full bg-gray-100 px-12 rounded-2xl py-14">
            <h3 className="text-2xl font-bold text-center">
              Find Freelance Gigs
            </h3>
            <div className="flex justify-center">
              <img
                src={FreelanceImage}
                height={"250"}
                width={"250"}
                alt="freelance-img"
              />
            </div>
            <p className="text-2xl leading-normal mt-2">
              Use LeadZLab to search for <Mark>relevant</Mark> subreddits or
              posts where individuals or businesses are <Mark>seeking</Mark>{" "}
              freelancers for specific projects.
            </p>
          </div>
        </div>
        <div className="lg:col-span-2 xl:col-auto">
          <div className="flex flex-col justify-between w-full h-full bg-gray-100 px-12 rounded-2xl py-14 dark:bg-trueGray-800">
            <h3 className="text-2xl font-bold text-center">
              Targeted Market Research
            </h3>
            <div className="flex justify-center">
              <img
                src={MarketResearchImage}
                height={"250"}
                width={"250"}
                alt="market-research-img"
              />
            </div>

            <p className="text-2xl leading-normal mt-3">
              Gather valuable insights by <Mark>monitoring</Mark> discussions
              and trends on Reddit, helping you better understand your target
              audience and refine your <Mark>marketing strategies.</Mark>
            </p>
          </div>
        </div>
        <div className="lg:col-span-2 xl:col-auto">
          <div className="flex flex-col justify-between w-full h-full bg-gray-100 px-12 rounded-2xl py-14 dark:bg-trueGray-800">
            <h3 className="text-2xl font-bold text-center">
              Targeted Market Research
            </h3>
            <div className="flex justify-center">
              <img
                src={SupportImage}
                height={"280"}
                width={"-3"}
                alt="support-img"
              />
            </div>

            <p className="text-2xl leading-normal mt-3">
              Monitor mentions of your product on Reddit, take{" "}
              <Mark>proactive</Mark> actions by responding to{" "}
              <Mark>feedback</Mark>, <Mark>concerns</Mark>, and building
              stronger relationships.
            </p>
          </div>
        </div>
      </div>
    </Container>
  );
};

function Mark(props) {
  return (
    <>
      {" "}
      <mark className="text-indigo-800 bg-indigo-100 rounded-md ring-indigo-100 ring-4 dark:ring-indigo-900 dark:bg-indigo-900 dark:text-indigo-200">
        {props.children}
      </mark>{" "}
    </>
  );
}

export default Testimonials;
