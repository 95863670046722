import PROTECTED_ROUTES from "../constants/index";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Sidebar from "../components/Sidebar/Sidebar";
// import AlertModal from "../components/AlertModal/AlertModal";
import { useAuthContext } from "../hooks/useAuthContext";
import { useEffect, useState } from "react";
// import PageNotFound from '../pages/Common/PageNotFound/PageNotFound';

const RoleRoutes = () => {
  const { user } = useAuthContext();

  const location = useLocation();
  const [toShowSidebar, setToShowSidebar] = useState(false);

  const checkToShowSidebar = () => {
    const ROUTES_WITHOUT_SIDEBAR = PROTECTED_ROUTES?.filter(
      (route) => route.fullScreen
    )?.map((route) => route.link);
    if (ROUTES_WITHOUT_SIDEBAR.includes(location.pathname))
      setToShowSidebar(false);
    else setToShowSidebar(true);
  };

  useEffect(() => {
    checkToShowSidebar();
  }, [location.pathname]);

  return user ? (
    <div className="flex w-full">
      <div className="w-100">
        {toShowSidebar && (
          <Sidebar PROTECTED_ROUTES={PROTECTED_ROUTES} user={user} />
        )}
      </div>
      <div></div>
      <div className="w-full">
        <Routes>
          {PROTECTED_ROUTES.map((route) => (
            <Route
              key={route.name}
              path={route.link}
              exact
              element={route.component}
            />
          ))}
          {/* <Route path="/profile" element={<Profile />} /> */}
          {/* <Route path="*" element={<PageNotFound />} /> */}
        </Routes>
        {/* <AlertModal /> */}
      </div>
    </div>
  ) : (
    <Navigate to="/login" />
  );
};

export default RoleRoutes;
