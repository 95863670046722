// * React Import
import { useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import Logo from "../../assets/Logo(500x500).png";

// * Environment Imports
import { environment } from "../../environments/environment";

// * Axios Imports
import axios from "axios";
import { toast } from "react-toastify";

// * Import Context
import { useAuthContext } from "../../hooks/useAuthContext";

export default function ChangePassword() {
  document.title = `Change Password | LeadZLab`;

  const { user } = useAuthContext();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (event) => {
    setLoading(true);

    event.preventDefault();
    const formInput = new FormData(event.currentTarget);

    // * Check if new password  === confirm password
    if (formInput.get("password") !== formInput.get("confirm-password")) {
      toast.error(
        `Password and Confirm Password do not match. Please try again.`,
        {
          position: "top-right",
          autoClose: true,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
        }
      );
      setLoading(false);
      return;
    }

    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");

    if (!token) {
      toast.error(`Password link has expired. Please try again`, {
        position: "top-right",
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
      setLoading(false);
      return;
    }

    // * Change password route
    try {
      let { data } = await axios.post(
        `${environment.BACKEND}/user/auth/change-password`,
        {
          token,
          password: formInput.get("password"),
        },
        { headers: { "Content-Type": "application/json" } }
      );
      toast.success(data.msg, {
        position: "top-right",
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
      setLoading(false);
    } catch (err) {
      toast.error(`${err?.response?.data?.msg ?? err.message}`, {
        position: "top-right",
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) navigate("/dashboard");
  }, [user]);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <div className="bg-indigo-100 h-screen w-screen flex items-center">
      <div className="mx-auto p-10 bg-white rounded-md overflow-hidden shadow-md w-screen lg:w-1/2">
        <div className="py-4 rounded px-6 w-full flex justify-content items-center justify-center">
          <img src={Logo} width={"100"} alt="logo" className="rounded-lg" />
        </div>
        <div className="py-4 rounded px-6 w-full flex justify-content items-center justify-center">
          <h1 className="text-3xl font-semibold text-gray-800 mb-4">
            Set new password
          </h1>{" "}
        </div>
        <form className="px-6 py-8" onSubmit={(e) => handleSubmit(e)}>
          <div className="mb-6">
            <label className="block text-gray-700 font-bold mb-2">
              New Password
            </label>
            <div className="relative">
              <input
                className="appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="password"
                id="password"
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
              />
              <button
                type="button"
                className="absolute top-0 right-0 h-full w-10 text-gray-400 hover:text-gray-500 flex items-center justify-center"
                onClick={handleClickShowPassword}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className="mb-6">
            <label className="block text-gray-700 font-bold mb-2">
              Confirm Password
            </label>
            <div className="relative">
              <input
                className="appearance-none border rounded-md w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="confirm-password"
                id="confirm-password"
                type={showPassword ? "text" : "password"}
                placeholder="Enter your password"
              />
              <button
                type="button"
                className="absolute top-0 right-0 h-full w-10 text-gray-400 hover:text-gray-500 flex items-center justify-center"
                onClick={handleClickShowPassword}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div className=" flex items-center justify-center">
            <button
              className="inline-block px-4 py-2 text-white bg-indigo-600 hover:bg-indigo-500 rounded w-80 text-center focus:outline-none focus:shadow-outline flex items-center justify-center"
              type="submit"
              disabled={loading}
            >
              {" "}
              {loading ? (
                <ThreeDots
                  radius="6"
                  height={25}
                  color="#ffffff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              ) : (
                <>Change Password</>
              )}
            </button>
          </div>

          <div className="text-center mt-5">
            Go to{" "}
            <NavLink to={"/login"} className="text-blue-500 font-bold">
              Login
            </NavLink>
          </div>
        </form>
      </div>
    </div>
  );
}
