// * Import pages
import Dashboard from "../pages/Dashboard/Dashboard";
import Profile from "../pages/Profile/Profile";
import JobDetail from "../pages/JobDetail/JobDetail";
import ExecutionDetail from "../pages/ExecutionDetail/ExecutionDetails";
import ExecutionsPage from "../pages/ExecutionsPage/ExecutionsPage";
import JobsPage from "../pages/JobsPage/JobsPage";
import CreateJob from "../pages/CreateJob/CreateJob";
import PricingPage from "../pages/Pricing/Pricing";
import PaymentRedirectPage from "../pages/PaymentRedirect/PaymentRedirect";
import AlertsPage from "../pages/AlertPage/AlertPage";
import FeedbackPage from "../pages/FeedbackPage/FeedbackPage";

// * Import Icons
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import PaymentsIcon from "@mui/icons-material/Payments";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

const PROTECTED_ROUTES = [
  {
    fullScreen: false,
    includedInSidebar: true,
    link: "/dashboard",
    name: "Dashboard",
    component: <Dashboard />,
    icon: <DashboardIcon fontSize="small" />,
    key: 1,
  },
  {
    fullScreen: false,
    includedInSidebar: true,
    link: "/jobs",
    name: "Lead Matchers",
    component: <JobsPage />,
    icon: <AccessTimeIcon fontSize="small" />,
    key: 2,
  },
  {
    fullScreen: false,
    includedInSidebar: true,
    link: "/executions",
    name: "Executions",
    component: <ExecutionsPage />,
    icon: <PlaylistAddCheckIcon fontSize="small" />,
    key: 3,
  },
  {
    fullScreen: false,
    includedInSidebar: true,
    link: "/alerts",
    name: "Alerts",
    component: <AlertsPage />,
    icon: <AccessAlarmIcon fontSize="small" />,
    key: 9,
  },
  {
    fullScreen: false,
    includedInSidebar: true,
    link: "/pricing",
    name: "Pricing",
    component: <PricingPage />,
    icon: <PaymentsIcon fontSize="small" />,
    key: 4,
  },

  {
    fullScreen: false,
    includedInSidebar: true,
    link: "/profile",
    name: "Profile",
    component: <Profile />,
    icon: <PermIdentityIcon fontSize="small" />,
    key: 5,
  },
  {
    fullScreen: false,
    includedInSidebar: true,
    link: "/feedback",
    name: "Feedback",
    component: <FeedbackPage />,
    icon: <QuestionMarkIcon fontSize="small" />,
    key: 10,
  },
  {
    fullScreen: false,
    includedInSidebar: false,
    link: "/job-detail",
    name: "Job Detail",
    component: <JobDetail />,
    key: 6,
  },
  {
    fullScreen: true,
    includedInSidebar: false,
    link: "/execution-detail",
    name: "Execution Detail",
    component: <ExecutionDetail />,
    key: 6,
  },
  {
    fullScreen: false,
    includedInSidebar: false,
    link: "/create-job",
    name: "Create Job",
    component: <CreateJob />,
    key: 7,
  },
  {
    fullScreen: false,
    includedInSidebar: false,
    link: "/payment-redirect",
    name: "Payment Redirect",
    component: <PaymentRedirectPage />,
    key: 8,
  },
];

export default PROTECTED_ROUTES;
