// * React Import
import { useState } from "react";
import { Fade } from "react-awesome-reveal";
import LandingPageImage from "../../../assets/landing.png";
import JobPageImage from "../../../assets/job.png";
import ScrappedImage from "../../../assets/scrapped.png";
import Logo from "../../../assets/Logo.svg";

// import { toast } from "react-toastify";

// * Package Imports
// import axios from "axios";

// * Environment Imports
// import { environment } from "../../environments/environment";

// * Component imports
import FeatureCard from "./components/FeatureCard";

export default function LandingPage() {
  const [featureSet] = useState([
    {
      title: "Lead Generation",
      description:
        "Efficiently find potential customers and generate leads on Reddit.",
    },
    {
      title: "Post Scraper",
      description:
        "Save relevant posts and conversations to your LeadZLab account for easy access and follow-up.",
    },
    {
      title: "All Your Potential Leads in One Place",
      description:
        "Keep track of all your potential leads on Reddit in one central location.",
    },
    {
      title: "Customizable Post Scheduling",
      description:
        "Schedule posts to be scraped and saved on a regular basis to stay on top of your lead generation efforts.",
    },
    {
      title: "Built-in Commenting Tool",
      description:
        "Engage with potential customers directly through our built-in commenting tool.",
    },
    {
      title: "Efficient Data Management",
      description:
        "Our user-friendly interface and database management system makes it easy to manage your lead generation efforts and track your results.",
    },
  ]);

  const scrollToElement = (elementId) => {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  // const [email, setEmail] = useState("");
  // const [justification, setJustification] = useState("");

  // const handleEmailChange = (event) => {
  //   setEmail(event.target.value);
  // };

  // const handleJustificationChange = (event) => {
  //   setJustification(event.target.value);
  // };

  // const handleSubmit = async (event) => {
  //   try {
  //     event.preventDefault();

  //     let { data } = await axios.post(
  //       `${environment.BACKEND}/user/beta/request-access`,
  //       {
  //         email,
  //         justification,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );
  //     toast.success(`${data.message}`, {
  //       position: "top-right",
  //       autoClose: true,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: 0,
  //     });
  //   } catch (err) {
  //     toast.error(`${err?.response?.data?.message ?? err.message}`, {
  //       position: "top-right",
  //       autoClose: true,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: 0,
  //     });
  //   }
  // };

  return (
    <div>
      <nav className="flex justify-between items-center px-4 py-3 bg-gray-800 fixed top-0 w-full z-50">
        <div className="mr-6 text-white">
          <img src={Logo} width={"175"} alt="logo" />
        </div>
        <div className="ml-auto">
          <a
            onClick={() => scrollToElement("beta-form")}
            className="inline-block px-4 py-2 text-white bg-purple-500 hover:bg-purple-600 rounded"
          >
            Join Beta
          </a>
        </div>
      </nav>
      <div className="flex bg-gradient-to-b from-gray-900 to-gray-800 ">
        <div className="w-full md:w-1/2 h-screen flex items-center justify-center">
          <div className="px-10 py-15 flex-col">
            <Fade cascade damping={0.2} direction={"up"}>
              <div className="h1 text-white text-5xl font-bold  mb-10 mt-10 md:mt-0">
                Propel Your Business Forward through Reddit Keyword Alert
                Monitoring
              </div>
              <div></div>
              <p className="text-2xl  text-gray-200 mb-8 mb-10">
                LeadZLab is an advanced tool for finding and engaging potential
                customers on Reddit. Request beta access now to grow your sales
                and revenue with us.
              </p>
              <div className="ml-auto md:mb-20">
                <a
                  onClick={() => scrollToElement("beta-form")}
                  className="inline-block px-4 py-2 text-white bg-purple-500 hover:bg-purple-600 rounded w-80 text-center"
                >
                  Join Beta
                </a>
              </div>
            </Fade>
          </div>
        </div>
        <div className="hidden md:flex w-1/2 h-screen flex items-center justify-center">
          <Fade cascade damping={0.2}>
            <img src={LandingPageImage} className="mb-20 mr-10 " />
          </Fade>
        </div>
      </div>
      <div className="flex bg-gradient-to-b from-gray-900 to-gray-800 min-h-screen items-center  pt-10  pb-10">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl text-white font-bold mb-8 text-center">
            Explore Our Platform
          </h2>
          <div className="grid grid-cols-1 gap-8">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="flex flex-col items-center md:col-span-2">
                <img
                  src={JobPageImage}
                  alt="Webpage 1"
                  className="object-cover rounded-lg shadow-md mb-4"
                />
              </div>
              <div className="text-3xl text-white font-bold mb-4 text-center flex justify-center items-center h-full">
                <p>
                  Set up jobs to scrape and never miss a post from a potential
                  lead on Reddit!
                </p>
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="text-3xl text-white font-bold mb-4 text-center flex justify-center items-center h-full">
                <p>
                  Find all the results in a cohesive table to quickly scan and
                  target a potential customer!
                </p>
              </div>
              <div className="flex flex-col items-center md:col-span-2">
                <img
                  src={ScrappedImage}
                  alt="Webpage 1"
                  className="object-cover rounded-lg shadow-md mb-4"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex-col bg-gradient-to-b from-gray-800 to-gray-800 md:h-screen">
        <div className="w-full mb-20 flex-col items-center justify-center">
          <div className="px-10 flex-col">
            <div className="h1 text-white text-4xl font-bold mb-10 justify-center text-center">
              Top problems faced when generating leads through Reddit
            </div>
            <div className="w-full flex flex-row item justify-center">
              <div className="flex w-3/4 text-center flex-col justify-between md:flex-row border-4 border-white  border-t-purple-500 rounded-t-lg rounded-b-lg divide-y divide-purple-200 md:divide-x shadow-lg">
                <div className="w-full md:w-1/2 lg:w-1/3 px-4 py-6 bg-white ">
                  <h2 className="text-3xl text-gray-900 font-bold mb-2 ">
                    Untapped potential
                  </h2>
                  <p className="text-gray-700 leading-relaxed">
                    Businesses are missing out on potential leads from Reddit.
                  </p>
                </div>
                <div className="w-full md:w-1/2 lg:w-1/3 px-4 py-6 bg-white ">
                  <h2 className="text-3xl text-gray-900 font-bold mb-2">
                    Difficulty leveraging Reddit
                  </h2>
                  <p className="text-gray-700  text-gray-900 leading-relaxed">
                    Many businesses struggle to use Reddit effectively to
                    generate leads.
                  </p>
                </div>
                <div className="w-full md:w-1/2 lg:w-1/3 px-4 py-6 bg-white">
                  <h2 className="text-3xl font-bold mb-2">No centralization</h2>
                  <p className="text-gray-700 leading-relaxed ">
                    There is currently no comprehensive platform for businesses
                    to find and engage with potential customers on Reddit.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex items-center justify-center">
          <div className="px-10 flex-col">
            <Fade cascade damping={0.2} direction={"up"}>
              <div className="h1 text-white text-4xl font-bold mb-10 justify-center text-center">
                Leverage LeadZLab and generate leads
              </div>
            </Fade>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {featureSet.map((feature, index) => (
                <FeatureCard
                  key={index}
                  title={feature.title}
                  description={feature.description}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* <div
        className="text-white bg-gradient-to-b from-gray-800 to-gray-800 md:h-screen flex flex-col items-center justify-center py-8 md:py-0"
        id="beta-form"
      >
        <h1 className="text-3xl font-bold mb-4 text-center">
          Join LeadZLab Beta Access
        </h1>
        <p className="text-2xl mb-4 text-center">
          Be among the first to experience the power of LeadZLab. Sign up below
          to get early access to our beta version.
        </p>
        <div className="w-3/4 text-xl">
          <form onSubmit={handleSubmit} className="flex flex-col">
            <label htmlFor="email" className="mb-2">
              Email:
            </label>
            <input
              type="email"
              id="email"
              placeholder="Your email address"
              value={email}
              onChange={handleEmailChange}
              className="mb-4 p-4 rounded-lg border-gray-300 text-black outline-none"
              required
            />
            <label htmlFor="justification" className="mb-2">
              Justification (optional):
            </label>
            <textarea
              rows={4}
              id="justification"
              placeholder="Why would you like to get beta access and how it could help your business?"
              value={justification}
              onChange={handleJustificationChange}
              className="mb-4 p-4 rounded-lg border-gray-300 text-black outline-none"
            ></textarea>
            <div className="w-full text-center">
              <button
                type="submit"
                className="font-bold bg-purple-500 text-white py-2 px-4 rounded hover:bg-purple-600 mt-4 w-2/4"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div> */}

      <footer className="bg-gray-900 py-8">
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row justify-between items-center">
            <div className="text-white text-sm mb-4 md:mb-0">
              &copy; 2023 LeadZLab. All rights reserved.
            </div>
            <div className="flex items-center space-x-4">
              <a
                href="/terms-of-service"
                className="text-white hover:text-gray-400"
              >
                Terms of Service
              </a>
              <a
                href="/privacy-policy"
                className="text-white hover:text-gray-400"
              >
                Privacy Policy
              </a>
              <a href="/contact-us" className="text-white hover:text-gray-400">
                Contact Us
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}
