import React from "react";

const ContactUs = () => {
  document.title = `Contact | LeadZLab`;

  window.location.href = "https://leadzlab.co/contact-us";

  return <div></div>;
};

export default ContactUs;
