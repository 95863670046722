/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

// * Component imports
import FixedBanner from "../../components/FixedBanner/FixedBanner";
import WarningIcon from "@mui/icons-material/Warning";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import BoltIcon from "@mui/icons-material/Bolt";

// * Environment Imports
import { environment } from "../../environments/environment";

// * Axios Imports
import axios from "axios";

// * Import Context
import { useAuthContext } from "../../hooks/useAuthContext";

function FeedbackPage() {
  document.title = `Feedback | LeadZLab`;

  // * Plan details
  const [plan, setPlan] = useState("");
  const [trialValidUntil, setTrialValidUntil] = useState(false);

  // * Nudges
  const [quota, setQuota] = useState({});

  // * Auth context
  const { user } = useAuthContext();

  function calculateDateDifference(timestamp) {
    const now = new Date();
    const targetDate = new Date(timestamp);

    // Check if the target date is in the past
    if (targetDate < now) {
      return -1;
    }

    const timeDifference = targetDate - now;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    return daysDifference;
  }

  // * Fetch plan
  const getPlan = async () => {
    try {
      const { data } = await axios.get(`${environment.BACKEND}/user`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.accessToken}`,
        },
      });
      const userData = data.data;
      setPlan(userData.plan);

      setTrialValidUntil(
        calculateDateDifference(userData.subscriptionActiveTill)
      );
    } catch (err) {
      console.log(err.response.data);
    }
  };

  // * Check Quota
  const checkQuota = async () => {
    try {
      const { data } = await axios.get(`${environment.BACKEND}/user/quota`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.accessToken}`,
        },
      });
      setQuota(data.data);
    } catch (err) {
      console.log(err.response.data);
    }
  };

  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");

  // * Submit feedback form
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let { data } = await axios.post(
        `${environment.BACKEND}/user/submit-feedback`,
        {
          subject: subject,
          body: body,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.accessToken}`,
          },
        }
      );
      toast.success(data.message, {
        position: "top-right",
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
    } catch (err) {
      toast.error(`${err?.response?.data?.message ?? err.message}`, {
        position: "top-right",
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
    }
  };

  useEffect(() => {
    getPlan();
    checkQuota();
  }, []);

  return (
    <div className="container dashboard w-4/5">
      {plan === "free" ? (
        trialValidUntil < 0 ? (
          <FixedBanner
            icon={<WarningIcon />}
            message={`Your free trial has expired. Please upgrade now to continue generating leads`}
            actionText="Upgrade"
            actionLink="/pricing"
            color="red"
          />
        ) : (
          <FixedBanner
            icon={<BoltIcon />}
            message={`Your free trial expires in ${trialValidUntil} days`}
            actionText="Upgrade"
            actionLink="/pricing"
            color="indigo"
          />
        )
      ) : plan === "" ? null : (
        <FixedBanner
          icon={<VerifiedUserIcon />}
          message={`You are currently on the ${plan?.toUpperCase()} plan`}
          // actionText="Upgrade"
          // actionLink="/pricing"
          color="yellow"
        />
      )}
      {/* Job limit nudge */}
      {quota.jobsCreated >= quota.jobsAllowed ? (
        <FixedBanner
          icon={<PriorityHighIcon />}
          message={`You will not be able to create any more lead matchers. Upgrade plan to create more. (${quota.jobsCreated}/${quota.jobsAllowed})`}
          actionText="Upgrade"
          actionLink="/pricing"
          color="gray"
        />
      ) : null}
      {/* Execution limit nudge */}
      {quota.executionsCreated >= quota.executionsAllowed ? (
        <FixedBanner
          icon={<PriorityHighIcon />}
          message={`Lead matchers have paused for today. Wait 24 hours for fresh leads, or upgrade plan. (${quota.executionsCreated}/${quota.executionsAllowed})`}
          actionText="Upgrade"
          actionLink="/pricing"
          color="gray"
        />
      ) : null}
      <h1 className="text-3xl font-bold mb-4">Feedback</h1>
      <div className="mb-4">
        <p className="mb-1">
          Please provide your thoughts or suggestions to help us improve.
        </p>
        <p className="mb-1">
          LeadZLab values communication and appreciates your input. Feel free to
          reach out for any support,{" "}
          <b className="underline">feature requests</b> or{" "}
          <b className="underline">suggestions.</b>
        </p>
        <p className="text-gray-600 mb-2">Contact us at: support@leadzlab.co</p>
      </div>
      <form
        onSubmit={handleSubmit}
        className="bg-white p-4 rounded-lg shadow-md"
      >
        <div className="mb-4">
          <label
            htmlFor="subject"
            className="block text-gray-700 font-semibold mb-1"
          >
            Subject
          </label>
          <input
            type="text"
            id="subject"
            className="w-full border rounded-lg py-2 px-3 focus:outline-none focus:border-indigo-500"
            placeholder="Request for a discount"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="body"
            className="block text-gray-700 font-semibold mb-1"
          >
            Body
          </label>
          <textarea
            id="body"
            placeholder="I love LeadZLab, I was wondering if I can have a discount?"
            className="w-full border rounded-lg py-2 px-3 focus:outline-none focus:border-indigo-500"
            value={body}
            onChange={(e) => setBody(e.target.value)}
            rows="4"
            required
          />
        </div>
        <button
          type="submit"
          className="bg-indigo-500 text-white py-2 px-4 rounded-lg hover:bg-indigo-600"
        >
          Submit
        </button>
      </form>
    </div>
  );
}

export default FeedbackPage;
