/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedin, faReddit } from "@fortawesome/free-brands-svg-icons";

// * Axios Imports
import axios from "axios";

// * Environment Imports
import { environment } from "../../environments/environment";

// * Import Context
import { useAuthContext } from "../../hooks/useAuthContext";

const ExecutionsPage = () => {
  document.title = `Executions | LeadZLab`;

  const [executions, setExecutions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loader, setLoader] = useState(true);

  const itemsPerPage = 15; // Number of executions per page
  const maxDisplayedPages = 10;

  // * Auth context
  const { user } = useAuthContext();

  const getExecutions = async (page) => {
    try {
      let { data } = await axios.get(
        `${environment.BACKEND}/resources/executions/all?page=${page}&limit=${itemsPerPage}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.accessToken}`,
          },
        }
      );
      setExecutions(data.data.executions);
      setTotalPages(data.data.totalPages);
      setLoader(false);
    } catch (err) {
      console.log(err.response.data);
      setLoader(false);
    }
  };

  useEffect(() => {
    getExecutions(currentPage);
  }, [currentPage]);

  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getDisplayedPageNumbers = () => {
    const firstDisplayedPage = Math.max(
      currentPage - Math.floor(maxDisplayedPages / 2),
      1
    );
    const lastDisplayedPage = Math.min(
      firstDisplayedPage + maxDisplayedPages - 1,
      totalPages
    );
    return Array.from(
      { length: lastDisplayedPage - firstDisplayedPage + 1 },
      (_, index) => firstDisplayedPage + index
    );
  };

  return (
    <div className="container dashboard w-4/5">
      <h1 className="text-3xl font-bold mb-4">Executions</h1>
      {!loader || <Skeleton count={5} />}
      {executions.length === 0 ? null : (
        <>
          {/* <h1 className="text-3xl font-bold my-4">Recent Executions</h1> */}
          <div className="bg-white rounded-lg shadow">
            <table className="w-full">
              <thead>
                <tr>
                  <th className="py-2 px-4 bg-gray-100">Executed At</th>
                  <th className="py-2 px-4 bg-gray-100">Type</th>
                  <th className="py-2 px-4 bg-gray-100">Posts Found</th>
                  <th className="py-2 px-4 bg-gray-100">Job Name</th>
                  <th className="py-2 px-4 bg-gray-100">View</th>
                </tr>
              </thead>
              <tbody>
                {executions.map((exec, index) => (
                  <tr
                    key={exec._id}
                    className={`${
                      index % 2 === 0 ? "bg-white" : "bg-gray-100"
                    }`}
                  >
                    <td className="py-2 px-4 text-center">
                      {new Date(exec.createdAt).toLocaleString([], {
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                      })}
                    </td>
                    <td className="py-2 px-4 text-center">
                      {exec.job_id.type === "linkedin" && (
                        <FontAwesomeIcon
                          icon={faLinkedin}
                          style={{ color: "#0077b5", fontSize: "32px" }}
                        />
                      )}
                      {exec.job_id.type === "reddit" && (
                        <FontAwesomeIcon
                          icon={faReddit}
                          style={{ color: " #ff4500", fontSize: "30px" }}
                        />
                      )}
                    </td>
                    <td className="py-2 px-4 text-center">
                      {exec.posts.length}
                    </td>
                    <td className="py-2 px-4 text-center">
                      {exec.job_id.jobName}
                    </td>
                    <td className="py-2 px-4 text-center">
                      <a
                        href={`/execution-detail?execution_id=${exec._id}`}
                        className="text-blue-500 hover:text-blue-700"
                      >
                        <NavLink
                          to={`/execution-detail?execution_id=${exec._id}`}
                        >
                          View
                        </NavLink>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="flex justify-center mt-4">
            <div className="pagination">
              {currentPage > 1 && (
                <button
                  onClick={handlePreviousPage}
                  className="pagination-item bg-indigo-500 text-white px-1 py-1 rounded-full mx-1"
                >
                  <KeyboardArrowLeftIcon />
                </button>
              )}

              {getDisplayedPageNumbers().map((pageNumber) => (
                <button
                  key={pageNumber}
                  onClick={() => setCurrentPage(pageNumber)}
                  className={`pagination-item ${
                    pageNumber === currentPage
                      ? "bg-indigo-500 text-white"
                      : "bg-gray-200"
                  } hover:bg-indigo-500 hover:text-white px-3 py-1 rounded-full mx-1`}
                >
                  {pageNumber}
                </button>
              ))}

              {currentPage < totalPages && (
                <button
                  onClick={handleNextPage}
                  className="pagination-item bg-indigo-500 text-white px-1 py-1 rounded-full mx-1"
                >
                  <KeyboardArrowRightIcon />
                </button>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ExecutionsPage;
