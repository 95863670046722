// * React Import
import * as React from "react";
import { Fade } from "react-awesome-reveal";
import PropTypes from "prop-types";

const FeatureCard = ({ title, description }) => {
  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      {}
      <Fade damping={0.2} direction={"up"}>
        <h3 className="text-lg font-bold mb-3">{title}</h3>
        <p className="text-gray-600">{description}</p>
      </Fade>
    </div>
  );
};

FeatureCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default FeatureCard;
