import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { environment } from "../../environments/environment";
import { useAuthContext } from "../../hooks/useAuthContext";
import "./Profile.css";

function ProfilePage() {
  document.title = `Profile | LeadZLab`;

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [redditProfileId, setRedditProfileId] = useState("");
  const [plan, setPlan] = useState("");
  const [subscriptionStartedAt, setSubscriptionStartedAt] = useState("");
  const [subscriptionActiveTill, setSubscriptionActiveTill] = useState("");
  const [subscriptionStatus, setSubscriptionStatus] = useState("canceled");

  // * Email marketing
  const [promotionMail, setPromotionMail] = useState(false);
  const [btsMails, setBtsMails] = useState(false);
  const [productUpdates, setProductUpdates] = useState(false);
  const [feedbackEmails, setFeedbackEmails] = useState(false);
  const [newsletter, setNewsletter] = useState(false);

  // * Update email preferences
  const handleUpdateEmailPreferences = async () => {
    try {
      // Send email preference updates to the backend
      await axios.put(
        `${environment.BACKEND}/user/email-preferences`,
        {
          promotionMail,
          btsMails,
          productUpdates,
          feedbackEmails,
          newsletter,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.accessToken}`,
          },
        }
      );
      toast.success(`Email preferences updated successfully`, {
        position: "top-right",
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
    } catch (err) {
      toast.error(`${err?.response?.data?.message ?? err.message}`, {
        position: "top-right",
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
    }
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };

  const handleRedditProfileIdChange = (e) => {
    setRedditProfileId(e.target.value);
  };

  // * Handle profile update
  const handleUpdateProfile = async () => {
    try {
      await axios.put(
        `${environment.BACKEND}/user`,
        {
          firstName: firstName,
          lastName: lastName,
          redditUsername: redditProfileId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.accessToken}`,
          },
        }
      );
      toast.success(`Profile updated successfully`, {
        position: "top-right",
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
    } catch (err) {
      toast.error(`${err?.response?.data?.message ?? err.message}`, {
        position: "top-right",
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
    }
  };

  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);

  const handleCancelSubscription = () => {
    setShowCancelConfirmation(true);
  };

  const handleConfirmCancelSubscription = async () => {
    try {
      await axios.post(
        `${environment.BACKEND}/user/payment/cancel`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.accessToken}`,
          },
        }
      );
      toast.success(`Subscription cancelled successfully`, {
        position: "top-right",
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
      setShowCancelConfirmation(false);
    } catch (err) {
      toast.error(`${err?.response?.data?.message ?? err.message}`, {
        position: "top-right",
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
    }
  };

  const handleCancelConfirmationCancel = () => {
    setShowCancelConfirmation(false);
  };

  const { user } = useAuthContext();

  // * Get user
  const getUser = async () => {
    try {
      const { data } = await axios.get(`${environment.BACKEND}/user`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.accessToken}`,
        },
      });
      const userData = data.data;
      setFirstName(userData.firstName);
      setLastName(userData.lastName);
      setRedditProfileId(userData.redditUsername);
      setPlan(userData.plan);
      setPromotionMail(userData.emailPreferences.promotions);
      setBtsMails(userData.emailPreferences.bts);
      setProductUpdates(userData.emailPreferences.productUpdates);
      setFeedbackEmails(userData.emailPreferences.feedback);
      setNewsletter(userData.emailPreferences.newsletter);
    } catch (err) {
      console.log(err.response.data);
    }
  };

  // * Get subscription
  const getSubscriptionStatus = async () => {
    try {
      const { data } = await axios.get(
        `${environment.BACKEND}/user/payment/subscription`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.accessToken}`,
          },
        }
      );
      const subscriptionData = data.subscription;

      setSubscriptionStartedAt(subscriptionData.subscriptionStartedAt);
      setSubscriptionActiveTill(subscriptionData.subscriptionActiveTill);
      setSubscriptionStatus(subscriptionData.subscriptionStatus);
    } catch (err) {
      console.log(err.response.data);
    }
  };

  useEffect(() => {
    getUser();
    getSubscriptionStatus();
  }, []);

  return (
    <div className="container py-8 profile w-4/5">
      <h1 className="text-3xl font-bold mb-4">Profile</h1>
      <h2 className="text-xl">
        Plan: <span className="font-bold">{plan.toUpperCase()}</span>
      </h2>
      {subscriptionStartedAt && (
        <p>
          Subscription started at:{" "}
          <span className="font-bold">
            {" "}
            {new Date(subscriptionStartedAt * 1000).toLocaleDateString()}
          </span>
        </p>
      )}
      {subscriptionActiveTill && (
        <p>
          Subscription active till:{" "}
          <span className="font-bold">
            {" "}
            {new Date(subscriptionActiveTill * 1000).toLocaleDateString()}
          </span>
        </p>
      )}
      {/* <br /> */}
      {showCancelConfirmation ? (
        <div className="mt-4">
          <p>Are you sure you want to cancel this subscription?</p>
          <button
            className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-md mr-2"
            onClick={handleConfirmCancelSubscription}
          >
            Confirm
          </button>
          <button
            className="bg-gray-500 hover:bg-gray-600 text-white py-2 px-4 rounded-md"
            onClick={handleCancelConfirmationCancel}
          >
            Cancel
          </button>
        </div>
      ) : (
        <div className="mt-4">
          {subscriptionActiveTill && (
            <>
              {subscriptionStatus === "canceled" ? (
                <span className="text-red-500 font-bold underline">
                  Subscription has been cancelled
                </span>
              ) : (
                <button
                  className="bg-red-600 hover:bg-red-700 text-white py-2 px-4 rounded-md mt-4"
                  disabled={subscriptionStatus === "canceled" ? true : false}
                  onClick={() => handleCancelSubscription()}
                >
                  Cancel Subscription
                </button>
              )}
            </>
          )}
        </div>
      )}
      <div className="mt-4 mb-4">
        <label className="block mb-2" htmlFor="firstNameInput">
          First Name
        </label>
        <input
          id="firstNameInput"
          type="text"
          className="border border-gray-300 rounded-md px-4 py-2 w-full"
          value={firstName}
          onChange={handleFirstNameChange}
        />
      </div>
      <div className="mb-4">
        <label className="block mb-2" htmlFor="lastNameInput">
          Last Name
        </label>
        <input
          id="lastNameInput"
          className="border border-gray-300 rounded-md px-4 py-2 w-full"
          value={lastName}
          onChange={handleLastNameChange}
        />
      </div>
      <div className="mb-4">
        <label className="block mb-2" htmlFor="redditProfileIdInput">
          Reddit Profile ID
        </label>
        <input
          id="redditProfileIdInput"
          type="text"
          className="border border-gray-300 rounded-md px-4 py-2 w-full"
          value={redditProfileId}
          onChange={handleRedditProfileIdChange}
        />
      </div>

      <button
        className="bg-gray-700 hover:bg-gray-800 text-white py-2 px-4 rounded-md"
        onClick={() => handleUpdateProfile()}
      >
        Update Profile
      </button>
      <div className="bg-gray-100 p-4 rounded-md mt-4">
        <h2 className="text-xl font-bold mb-4">Email Preferences</h2>
        <div className="mt-4 mb-4 flex items-center">
          <input
            id="receivePromotionMailCheckbox"
            type="checkbox"
            className="form-checkbox h-5 w-5 text-indigo-600 mr-2"
            checked={promotionMail}
            onChange={(e) => setPromotionMail(e.target.checked)}
          />
          <label
            className="text-indigo-800 font-medium cursor-pointer"
            htmlFor="receivePromotionMailCheckbox"
          >
            Receive Promotion Mail (This includes offers like discounts or
            freebies)
          </label>
        </div>
        <div className="mt-4 mb-4 flex items-center">
          <input
            id="btsMailsCheckbox"
            type="checkbox"
            className="form-checkbox h-5 w-5 text-indigo-600 mr-2"
            checked={btsMails}
            onChange={(e) => setBtsMails(e.target.checked)}
          />
          <label
            className="text-indigo-800 font-medium cursor-pointer"
            htmlFor="btsMailsCheckbox"
          >
            Receive Behind The Scenes updates (Learn more about the team behind
            LeadZLab)
          </label>
        </div>
        <div className="mt-4 mb-4 flex items-center">
          <input
            id="productUpdatesCheckbox"
            type="checkbox"
            className="form-checkbox h-5 w-5 text-indigo-600 mr-2"
            checked={productUpdates}
            onChange={(e) => setProductUpdates(e.target.checked)}
          />
          <label
            className="text-indigo-800 font-medium cursor-pointer"
            htmlFor="productUpdatesCheckbox"
          >
            Receive Product Updates (Stay on top of all the new features and
            modifications)
          </label>
        </div>
        <div className="mt-4 mb-4 flex items-center">
          <input
            id="feedbackEmailsCheckbox"
            type="checkbox"
            className="form-checkbox h-5 w-5 text-indigo-600 mr-2"
            checked={feedbackEmails}
            onChange={(e) => setFeedbackEmails(e.target.checked)}
          />
          <label
            className="text-indigo-800 font-medium cursor-pointer"
            htmlFor="feedbackEmailsCheckbox"
          >
            Receive Feedback Surveys (We are constantly evolving and learning
            for feedback)
          </label>
        </div>
        <div className="mt-4 mb-4 flex items-center">
          <input
            id="feedbackEmailsCheckbox"
            type="checkbox"
            className="form-checkbox h-5 w-5 text-indigo-600 mr-2"
            checked={newsletter}
            onChange={(e) => setNewsletter(e.target.checked)}
          />
          <label
            className="text-indigo-800 font-medium cursor-pointer"
            htmlFor="feedbackEmailsCheckbox"
          >
            Receive Newsletters (Get the latest strategies to improve lead
            generation)
          </label>
        </div>
        <button
          className="bg-gray-800 hover:bg-gray-700 text-white py-2 px-4 rounded-md "
          onClick={() => handleUpdateEmailPreferences()}
        >
          Update Email Preferences
        </button>
      </div>
    </div>
  );
}

export default ProfilePage;
