/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import React from "react";

// * Package Imports
import axios from "axios";
import { toast } from "react-toastify";

// * Environment import
import { environment } from "../../environments/environment";

// * Import Context
import { useAuthContext } from "../../hooks/useAuthContext";

const PricingPage = () => {
  document.title = `Pricing | LeadZLab`;

  // * Auth context
  const { user } = useAuthContext();

  const checkout = async (price_id) => {
    try {
      let { data } = await axios.post(
        `${environment.BACKEND}/user/payment/create-checkout-session`,
        {
          price_id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.accessToken}`,
          },
        }
      );
      window.location.href = data.url;
    } catch (err) {
      toast.error(`${err?.response?.data?.message ?? err.message}`, {
        position: "top-right",
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen py-10">
      <div className="max-w-5xl mx-auto px-4">
        <h1 className="text-3xl text-gray-800 font-bold text-center mb-10">
          Pricing Plans
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* BASIC Plan */}
          <div className="bg-white rounded-lg shadow-md p-6 flex flex-col">
            <h2 className="text-xl font-semibold mb-4">BASIC</h2>
            <p className="text-gray-600 mb-6">₹2000 per month ($25 approx)</p>
            <ul className="mb-6">
              <li className="flex items-center">
                <span className="mr-2">&#8226;</span>
                Create up to 3 Lead Matchers
              </li>
              <li className="flex items-center">
                <span className="mr-2">&#8226;</span>6 Executions per day
              </li>
              {/* <li className="flex items-center">
                <span className="mr-2">&#8226;</span>1 Email alert setup
              </li> */}
            </ul>
            {/* <button
              disabled
              className="bg-gray-300 text-gray-600 py-2 px-4 rounded w-full cursor-not-allowed"
            >
              Coming Soon
            </button> */}
            <button
              className="bg-gray-800 text-white py-2 px-4 rounded w-full  hover:bg-gray-900"
              onClick={() => checkout(process.env.REACT_APP_BASIC_PLAN)}
            >
              Buy Now
            </button>
          </div>

          {/* ULTRA Plan */}
          <div className="bg-white rounded-lg shadow-md p-6 flex flex-col">
            <h2 className="text-xl font-semibold mb-4">ULTRA</h2>
            <p className="text-gray-600 mb-6">₹4000 per month ($50 approx)</p>
            <ul className="mb-6">
              <li className="flex items-center">
                <span className="mr-2">&#8226;</span>
                Create up to 10 Lead Matchers
              </li>
              <li className="flex items-center">
                <span className="mr-2">&#8226;</span>
                30 Executions per day
              </li>
              {/* <li className="flex items-center">
                <span className="mr-2">&#8226;</span>3 Email alert setup
              </li> */}
            </ul>
            <button
              onClick={() => checkout(process.env.REACT_APP_ULTRA_PLAN)}
              className="bg-gray-800 text-white py-2 px-4 rounded w-full  hover:bg-gray-900"
            >
              Buy Now
            </button>
          </div>

          {/* PROFESSIONAL Plan */}
          {/* <div className="bg-white rounded-lg shadow-md p-6 flex flex-col">
            <h2 className="text-xl font-semibold mb-4">PROFESSIONAL</h2>
            <p className="text-gray-600 mb-6">₹8000 per month ($100 approx)</p>
            <ul className="mb-6">
              <li className="flex items-center">
                <span className="mr-2">&#8226;</span>
                Create up to 30 Lead Matchers
              </li>
              <li className="flex items-center">
                <span className="mr-2">&#8226;</span>
                60 Executions per day
              </li>
              <li className="flex items-center">
                <span className="mr-2">&#8226;</span>5 Email alert setup
              </li>
            </ul>
            <button
              disabled
              className="bg-gray-300 text-gray-600 py-2 px-4 rounded w-full cursor-not-allowed"
            >
              Coming Soon
            </button>
          </div> */}
        </div>

        <div className="mt-10">
          <h2 className="text-2xl font-semibold mb-4">FAQ</h2>

          {/* Collapsible FAQ */}
          <div className="border border-gray-300 rounded-lg p-4">
            <details className="mb-4">
              <summary className="font-semibold mb-2">
                What is the refund policy?
              </summary>
              <p className="text-gray-600">
                We offer a 3 day money-back guarantee. If you are not satisfied
                with our service, please contact our support team within 3 days
                of your subscription purchase for a full refund.
              </p>
            </details>

            <details className="mb-4">
              <summary className="font-semibold mb-2">
                How can I upgrade my plan?
              </summary>
              <p className="text-gray-600">
                Please contact support at support@leadzlab.co to assist you with
                the upgrade
              </p>
            </details>

            <details>
              <summary className="font-semibold mb-2">
                Can I cancel my subscription anytime?
              </summary>
              <p className="text-gray-600">
                Yes, you can cancel your subscription at any time. There are no
                long-term commitments or contracts. Once you cancel, your
                subscription will remain active until the end of the current
                billing cycle, and you will not be charged again.
              </p>
            </details>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;
