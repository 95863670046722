import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faPlay,
  faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCheckCircle,
  faTimesCircle,
} from "@fortawesome/free-regular-svg-icons";
import InputChipComponent from "../../components/InputChipComponent/InputChipComponent";
import { faLinkedin, faReddit } from "@fortawesome/free-brands-svg-icons";

// * Environment Imports
import { environment } from "../../environments/environment";

// * Import Context
import { useAuthContext } from "../../hooks/useAuthContext";

// eslint-disable-next-line react/prop-types
const Tooltip = ({ text, children, direction = "bottom" }) => {
  const getDirectionClass = () => {
    switch (direction) {
      case "left":
        return "left-0 -translate-x-full top-1/2 -translate-y-1/2";
      case "right":
        return "right-0 -translate-x-0 top-1/2 -translate-y-1/2";
      case "top":
        return "top-0 -translate-x-1/2 -translate-y-full";
      case "bottom":
      default:
        return "bottom-0 -translate-x-1/2 translate-y-full";
    }
  };

  return (
    <div className="relative inline-block">
      <div className="group inline-block">
        {children}
        <div
          className={`absolute hidden group-hover:block bg-indigo-500 text-white text-sm md:text-md py-2 px-3 rounded-lg shadow-lg whitespace-nowrap transform ${getDirectionClass()}`}
        >
          {" "}
          <div className="whitespace-normal md:whitespace-nowrap  font-bold">
            {text}
          </div>
        </div>
      </div>
    </div>
  );
};

const CreateJobPage = () => {
  document.title = `Create Job | LeadZLab`;

  const [keywords, setKeywords] = useState([]);
  const [subReddits, setSubreddits] = useState([]);
  const [jobName, setJobName] = useState("");
  const [durationSpan, setDurationSpan] = useState(12);
  const [redditTimeFilter, setRedditTimeFilter] = useState("month");
  const [linkedInTimeFilter, setLinkedInTimeFilter] = useState("day");
  const [selectedType, setSelectedType] = useState("linkedin");
  const [isUserConnectedWithLinkedIn, setIsUserConnectedWithLinkedIn] =
    useState(null);

  // * Auth context
  const { user } = useAuthContext();

  const navigate = useNavigate();

  // * Check if user is logged in with Linkedin
  const checkLinkedInConnection = async () => {
    try {
      await axios.get(`${environment.BACKEND}/user/check-linkedin`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.accessToken}`,
        },
      });
      setIsUserConnectedWithLinkedIn(true);
    } catch (err) {
      setIsUserConnectedWithLinkedIn(false);
    }
  };

  useEffect(() => {
    checkLinkedInConnection();
  }, []);

  // Function to handle type selection
  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
  };

  const handleJobNameChange = (e) => {
    setJobName(e.target.value);
  };

  const handleDurationChange = (e) => {
    setDurationSpan(parseInt(e.target.value));
  };

  const handleRedditTimeFilterChange = (value) => {
    setRedditTimeFilter(value);
  };

  const handleLinkedinTimeFilterChange = (value) => {
    setLinkedInTimeFilter(value);
  };

  const convertToRedditQuery = () => {
    const keywordQuery = keywords.map((keyword) => `"${keyword}"`).join(" OR ");
    const subredditQuery = subReddits
      .map((subreddit) => `subreddit:${subreddit}`)
      .join(" OR ");
    return `(${keywordQuery}) AND (${subredditQuery})`;
  };

  const handleCreateJob = async () => {
    try {
      if (parseInt(durationSpan) < 4 || parseInt(durationSpan) > 24)
        throw new Error("Interval must be between 4hr to 24hr");

      if (keywords.length === 0)
        throw new Error("Please enter at least one keyword to look for");

      // * Change request based on type
      if (selectedType === "linkedin") {
        let linkedInQuery = keywords
          .map((keyword) => `${keyword}`)
          .join(" or ");

        if (linkedInQuery.length > 500)
          throw new Error(
            "Please ensure that the keywords do not take more than 500 characters. Please create another Lead Matcher to handle more keywords"
          );
        // eslint-disable-next-line no-unreachable
        await axios.post(
          `${environment.BACKEND}/resources/job`,
          {
            jobName: jobName,
            q: linkedInQuery,
            timeFilter: linkedInTimeFilter,
            durationSpan: durationSpan,
            type: selectedType,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user?.accessToken}`,
            },
          }
        );
      } else if (selectedType === "reddit") {
        if (convertToRedditQuery().length - 9 > 500)
          throw new Error(
            "Please ensure that the keywords and subreddits do not take more than 500 characters. Please create another Lead Matcher to handle more keyword and subreddits"
          );

        if (subReddits.length === 0)
          throw new Error("Please enter at least one subreddit to monitor");

        const redditQuery = convertToRedditQuery();

        // eslint-disable-next-line no-unreachable
        await axios.post(
          `${environment.BACKEND}/resources/job`,
          {
            jobName: jobName,
            q: redditQuery,
            timeFilter: redditTimeFilter,
            durationSpan: durationSpan,
            type: selectedType,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user?.accessToken}`,
            },
          }
        );
      }

      toast.success(`Lead Matcher updated successfully`, {
        position: "top-right",
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
      navigate("/jobs");
    } catch (err) {
      toast.error(`${err?.response?.data?.message ?? err.message}`, {
        position: "top-right",
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
    }
  };

  return (
    <div className="container dashboard w-4/5">
      <h1 className="text-3xl font-bold">Lead Matcher Details</h1>
      {selectedType === "linkedin" && isUserConnectedWithLinkedIn !== null ? (
        <div
          className={`p-2  ${
            isUserConnectedWithLinkedIn
              ? "text-blue-800 bg-blue-200"
              : "text-red-800 bg-red-200"
          } text-white text-left rounded-lg mt-2 mb-2`}
        >
          <p>
            {isUserConnectedWithLinkedIn ? (
              <>
                <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                You are connected with LinkedIn
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faTimesCircle} className="mr-2" />
                You are not connected with LinkedIn. Please connect to continue
              </>
            )}
          </p>
        </div>
      ) : null}
      <div className="mb-4 w-full">
        <label htmlFor="type" className="text-gray-600">
          Select Type:
        </label>
        <div className="flex items-center w-full mt-2">
          <div className="flex items-center ml-2 w-full">
            {selectedType === "linkedin" && (
              <FontAwesomeIcon
                icon={faLinkedin}
                style={{ color: "#0077b5", fontSize: "32px" }}
              />
            )}
            {selectedType === "reddit" && (
              <FontAwesomeIcon
                icon={faReddit}
                style={{ color: " #ff4500", fontSize: "32px" }}
              />
            )}
            <select
              id="type"
              value={selectedType}
              onChange={handleTypeChange}
              className="border border-gray-300 rounded-md px-4 py-2 ml-2 w-full"
            >
              <option value="linkedin">LinkedIn</option>
              <option value="reddit">Reddit</option>
            </select>
          </div>
        </div>
      </div>
      {selectedType === "linkedin" ? (
        <a
          className="text-blue-500 underline hover:text-blue-700"
          target="__blank"
          href="https://docs.google.com/document/d/14impr-1XtM4_aNnWHOkM5OyvoYa7F4ckeRJvkGE9llQ/edit?usp=sharing"
        >
          <FontAwesomeIcon
            icon={faExternalLinkAlt}
            className="mr-2 "
            size="xs"
          />{" "}
          How to set it up? <br />
        </a>
      ) : null}

      {selectedType === "reddit" ? (
        <a
          className="text-blue-500 underline hover:text-blue-700"
          target="__blank"
          href="https://www.youtube.com/watch?v=y5PsnWsWUBI"
        >
          <FontAwesomeIcon icon={faPlay} className="mr-2 " size="xs" /> Watch
          demo
        </a>
      ) : null}

      <div className="mb-4 mt-4">
        <label htmlFor="jobName" className="text-gray-600">
          Lead Matcher Name:
        </label>
        <Tooltip text="Give your lead matcher a name for easier identification">
          <FontAwesomeIcon icon={faInfoCircle} className="ml-2 text-gray-500" />
        </Tooltip>

        <input
          type="text"
          id="jobName"
          placeholder="Potential clients"
          value={jobName}
          onChange={handleJobNameChange}
          className="border border-gray-300 rounded-md px-4 py-2 w-full"
        />
      </div>
      <div className="mb-4">
        <label htmlFor="query" className="text-gray-600">
          Keywords: <br />
          <a
            className="text-blue-500 underline hover:text-blue-700 text-sm"
            target="__blank"
            href="https://storage.googleapis.com/leadzlab/chips.mp4"
          >
            How to enter the keyword?{" "}
          </a>
          <br />
          <span className="text-sm text-indigo-500">
            Phrases or words that my &quot;potential customers&quot; would be
            using
          </span>
        </label>
        <InputChipComponent
          keywords={keywords}
          setKeywords={setKeywords}
          placeholder="need website, looking for agency, help needed with seo"
        />
        <div className="flex justify-end">
          <span
            className={`${
              convertToRedditQuery().length - 9 > 500
                ? "text-red-600"
                : "text-gray-600"
            }`}
          >
            {convertToRedditQuery().length - 9} / 500{" "}
            <Tooltip
              text={
                `Consider creating another Lead Matcher to support more keywords` +
                (selectedType === "linkedin" ? "" : "  and subreddit")
              }
              direction="left"
            >
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="ml-2 text-gray-500"
              />
            </Tooltip>
          </span>
        </div>
      </div>

      {selectedType === "linkedin" && (
        <>
          <div className="mb-4">
            <label htmlFor="datePosted" className="text-gray-600">
              Date Posted:
            </label>
            <select
              id="datePosted"
              value={linkedInTimeFilter}
              className="border border-gray-300 rounded-md px-4 py-2 w-full"
              onChange={(e) => handleLinkedinTimeFilterChange(e.target.value)}
            >
              <option value="day">Past 24 hours</option>
              <option value="week">Past week</option>
              <option value="month">Past month</option>
            </select>
          </div>
        </>
      )}

      {selectedType === "reddit" && (
        <div className="mb-4">
          <label htmlFor="query" className="text-gray-600">
            Subreddits to look in:
            <br />
            <span className="text-sm text-indigo-500">
              Subreddits where my customers are talking in
            </span>
          </label>
          <InputChipComponent
            keywords={subReddits}
            setKeywords={setSubreddits}
            placeholder="startups, entrepreneur, indiebiz, sideproject"
          />
        </div>
      )}

      <div className="mb-4">
        <label htmlFor="intervalHours" className="text-gray-600">
          Interval (hours):
          <br />
          <span className="text-sm">Fetch new posts after how many hours?</span>
        </label>
        <input
          type="number"
          id="intervalHours"
          value={durationSpan}
          onChange={handleDurationChange}
          min="4"
          max="24"
          className="border border-gray-300 rounded-md px-4 py-2 w-full"
        />
      </div>

      {selectedType === "reddit" && (
        <div className="mb-4">
          <label htmlFor="timeFilter" className="text-gray-600">
            Reddit Time Filter:
          </label>
          <select
            id="timeFilter"
            value={redditTimeFilter}
            className="border border-gray-300 rounded-md px-4 py-2 w-full"
            onChange={(e) => handleRedditTimeFilterChange(e.target.value)}
          >
            {/* <option value="hour">HOUR</option> */}
            <option value="day">DAY</option>
            <option value="week">WEEK</option>
            <option value="month">MONTH</option>
            {/* <option value="all">ALL</option> */}
          </select>
        </div>
      )}

      <button
        className={`bg-gray-700 hover:bg-gray-800 text-white py-2 px-4 rounded-md ${
          selectedType === "linkedin" && !isUserConnectedWithLinkedIn
            ? "opacity-50 cursor-not-allowed"
            : ""
        }`}
        onClick={handleCreateJob}
        disabled={selectedType === "linkedin" && !isUserConnectedWithLinkedIn}
      >
        Setup Lead Matcher
      </button>
    </div>
  );
};

export default CreateJobPage;
