import {
  FaceSmileIcon,
  ChartBarSquareIcon,
  CursorArrowRaysIcon,
  DevicePhoneMobileIcon,
  AdjustmentsHorizontalIcon,
  // SunIcon,
} from "@heroicons/react/24/solid";

import benefitOneImg from "../../../assets/benefit-one.png";
import benefitTwoImg from "../../../assets/benefit-two.png";

const benefitOne = {
  title: "How does it work?",
  desc: "",
  image: benefitOneImg,
  bullets: [
    {
      title: "Understand your customers",
      desc: "Then set up keywords that describe them. Set subreddit specific keywords or simply add phrases that your potential customers are using",
      icon: <FaceSmileIcon />,
    },
    {
      title: "Get results",
      desc: "Based on YOUR criteria, LeadZLab quickly fetches all the relevant conversations on Reddit for you to interact with and generate interest in your business or service",
      icon: <ChartBarSquareIcon />,
    },
    {
      title: "Interact!",
      desc: "From one simple interface, talk and engage with your leads, and CONVERT!",
      icon: <CursorArrowRaysIcon />,
    },
  ],
};

const benefitTwo = {
  title: "Thats not all...",
  desc: "",
  image: benefitTwoImg,
  bullets: [
    {
      title: "All Your potential leads in one place",
      desc: "Stop scouring various Subreddits. Make everything convenient",
      icon: <DevicePhoneMobileIcon />,
    },
    {
      title: "Targeted interaction",
      desc: "Why waste time talking to everyone? Quality over quantity",
      icon: <AdjustmentsHorizontalIcon />,
    },
    // {
    //   title: "Dark & Light Mode",
    //   desc: "Nextly comes with a zero-config light & dark mode. ",
    //   icon: <SunIcon />,
    // },
  ],
};

export { benefitOne, benefitTwo };
