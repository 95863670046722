// * React Import
import { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import Logo from "../../assets/Logo(500x500).png";

// * Environment Imports
import { environment } from "../../environments/environment";

// * Axios Imports
import axios from "axios";
import { toast } from "react-toastify";

// * Import Context
import { useAuthContext } from "../../hooks/useAuthContext";

export default function ForgotPassword() {
  document.title = `Send Reset Link | LeadZLab`;

  const { user } = useAuthContext();
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    setLoading(true);

    event.preventDefault();
    const formInput = new FormData(event.currentTarget);

    // * Sign up route
    try {
      await axios.post(
        `${environment.BACKEND}/user/auth/reset-password`,
        {
          email: formInput.get("email"),
        },
        { headers: { "Content-Type": "application/json" } }
      );
      toast.success(`An email with a reset password link has been sent`, {
        position: "top-right",
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
      setLoading(false);
    } catch (err) {
      toast.error(`${err?.response?.data?.message ?? err.message}`, {
        position: "top-right",
        autoClose: true,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user) navigate("/dashboard");
  }, [user]);

  return (
    <div className="bg-indigo-100 h-screen w-screen flex items-center">
      <div className="mx-auto p-10 bg-white rounded-md overflow-hidden shadow-md w-screen lg:w-1/2">
        <div className="py-4 rounded px-6 w-full flex justify-content items-center justify-center">
          <img src={Logo} width={"100"} alt="logo" className="rounded-lg" />
        </div>
        <div className="py-4 rounded px-6 w-full flex justify-content items-center justify-center">
          <h1 className="text-3xl font-semibold text-gray-800 mb-4">
            Forgot Password?
          </h1>{" "}
        </div>
        <form className="px-6 py-8" onSubmit={(e) => handleSubmit(e)}>
          <div className="mb-6">
            <label className="block text-gray-700 font-bold mb-2 text-lg">
              Email
            </label>
            <input
              className="appearance-none border rounded-md w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="email"
              id="email"
              type="email"
              placeholder="Enter your email address"
            />
          </div>
          <div className=" flex items-center justify-center">
            <button
              className="inline-block px-4 py-2 text-white bg-indigo-600 hover:bg-indigo-500 rounded w-80 text-center focus:outline-none focus:shadow-outline flex items-center justify-center"
              type="submit"
              disabled={loading}
            >
              {" "}
              {loading ? (
                <ThreeDots
                  radius="6"
                  height={25}
                  color="#ffffff"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              ) : (
                <>Send Reset Password Link</>
              )}
            </button>
          </div>

          <div className="text-center mt-5">
            Don&apos;t have an account?{" "}
            <NavLink to={"/signup"} className="text-blue-500 font-bold">
              Sign up
            </NavLink>
          </div>
        </form>
      </div>
    </div>
  );
}
