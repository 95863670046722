/* eslint-disable react/prop-types */

// * Imports
import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import axios from "axios";
import he from "he";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExternalLinkAlt,
  faTimes,
  faCaretUp,
  faCaretDown,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import MarkunreadOutlinedIcon from "@mui/icons-material/MarkunreadOutlined";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

// * Component imports
import FixedBanner from "../../components/FixedBanner/FixedBanner";
import WarningIcon from "@mui/icons-material/Warning";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
// import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import BoltIcon from "@mui/icons-material/Bolt";

// * Environment Imports
import { environment } from "../../environments/environment";

// * Import styles
import "./ExecutionDetail.css";

// * Import Context
import { useAuthContext } from "../../hooks/useAuthContext";

//TODO: Handle no posts found UI
//TODO: Show proper HTML with spacing and list

// Function to sanitize body content
const sanitizeBody = (body) => {
  if (!body) return "";

  // Decode HTML entities
  let decodedBody = he.decode(body);
  decodedBody = he.decode(decodedBody);

  /// Create a new DOMParser instance
  const parser = new DOMParser();

  // Parse the HTML string into a document
  const doc = parser.parseFromString(decodedBody, "text/html");

  // Get all the <a> tags from the document
  const linkElements = doc.querySelectorAll("a");

  // Add blue color and underline to each <a> tag
  linkElements.forEach((linkElement) => {
    linkElement.style.color = "blue";
    linkElement.style.textDecoration = "underline";
  });

  // Serialize the modified document back to HTML string
  return doc.documentElement.innerHTML;
};

const ExecutionPage = () => {
  document.title = `Execution | LeadZLab`;

  const [posts, setPosts] = useState([]);
  const [job, setJob] = useState({
    jobName: "",
    createdAt: "",
  });

  const [expandedPostId, setExpandedPostId] = useState(null);
  const [selectedPosts, setSelected] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [sortField, setSortField] = useState("post_created_at"); // The field to sort by
  const [sortOrder, setSortOrder] = useState("asc");

  // * Plan details
  const [plan, setPlan] = useState("");
  const [trialValidUntil, setTrialValidUntil] = useState(false);

  const [keywords, setKeywords] = useState([]);
  const [subReddits, setSubreddits] = useState([]);
  const [jobType, setJobType] = useState("linkedin");

  // * Handle sorting
  const handleSort = (field) => {
    if (field === sortField) {
      // If the same field is clicked, toggle the sort order
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      // If a different field is clicked, set the sort field and order to default (asc)
      setSortField(field);
      setSortOrder("asc");
    }
  };

  const togglePostExpansion = (postId) => {
    updateReadStatus(true, false, [postId]);

    // Check if the modal is currently open for the clicked post
    if (isModalOpen && expandedPostId === postId) {
      // Close the modal
      setIsModalOpen(false);
    } else {
      // Open the modal and set the expanded post ID
      setIsModalOpen(true);
      setExpandedPostId(postId);
    }
  };

  const truncateString = (string, maxLength) => {
    if (string?.length <= maxLength) {
      return string;
    }
    return string?.substring(0, maxLength) + "...";
  };

  const renderUrls = (urls) => {
    if (urls.length === 0) {
      return "--";
    }
    return (
      <div className="flex flex-col">
        {urls.map((url, index) => (
          <a
            key={index}
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:underline truncate"
            title={url}
          >
            {truncateString(url, 30)}
          </a>
        ))}
      </div>
    );
  };

  // * Auth context
  const { user } = useAuthContext();
  const location = useLocation();

  // * Convert to chips from reddit query (decode)
  function convertFromRedditQuery(redditQuery) {
    const keywordsRegex = /"(.*?)"/g;
    const subredditsRegex = /subreddit:(\w+)/g;

    const keywords = [];
    const subreddits = [];

    let match;
    while ((match = keywordsRegex.exec(redditQuery)) !== null) {
      keywords.push(match[1]);
    }

    while ((match = subredditsRegex.exec(redditQuery)) !== null) {
      subreddits.push(match[1]);
    }

    return { keywords, subreddits };
  }

  // * Fetch execution data
  const getExecutions = async () => {
    try {
      setLoading(true);
      const searchParams = new URLSearchParams(location.search);
      const executionId = searchParams.get("execution_id");
      let { data } = await axios.get(
        `${environment.BACKEND}/resources/executions/posts/${executionId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.accessToken}`,
          },
        }
      );
      setPosts(data.data.posts);
      setJobType(data.data.job.type);
      if (data.data.job.type === "reddit") {
        let chips = convertFromRedditQuery(data.data.job.q);
        setKeywords(chips.keywords);
        setSubreddits(chips.subreddits);
      } else {
        setKeywords(data.data.job.q.split(" or "));
      }

      setJob(data.data.job);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err.response.data);
    }
  };

  // * Handle select
  const handleSelect = (event) => {
    let updatedList = [...selectedPosts];
    if (event.target.checked)
      updatedList = [...selectedPosts, event.target.value];
    else updatedList.splice(selectedPosts.indexOf(event.target.value), 1);
    setSelected(updatedList);
  };

  const selectAll = () => {
    if (selectedPosts.length) setSelected([]);
    else {
      let list = [];
      posts.forEach((post) => list.push(post._id));
      setSelected(list);
    }
  };

  // * Check if selected
  const isSelected = (item) =>
    selectedPosts.includes(item) ? "bg-blue-100" : "";

  // * Update read status
  const updateReadStatus = async (
    is_read,
    updateSelectedPosts = true,
    posts = []
  ) => {
    try {
      await axios.put(
        `${environment.BACKEND}/resources/post/read-status`,
        {
          postIds: updateSelectedPosts ? selectedPosts : posts,
          is_read,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${user?.accessToken}`,
          },
        }
      );

      // * Update to is_read
      if (updateSelectedPosts) {
        setPosts((prevPosts) => {
          return prevPosts.map((post) => {
            if (selectedPosts.includes(post._id)) {
              return { ...post, is_read };
            }
            return post;
          });
        });
        setSelected([]);
      } else
        setPosts((prevPosts) => {
          return prevPosts.map((post) => {
            if (posts.includes(post._id)) {
              return { ...post, is_read };
            }
            return post;
          });
        });
    } catch (err) {
      window.alert(err.response.data.message);
    }
  };

  function calculateDateDifference(timestamp) {
    const now = new Date();
    const targetDate = new Date(timestamp);

    // Check if the target date is in the past
    if (targetDate < now) {
      return -1;
    }

    const timeDifference = targetDate - now;
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    return daysDifference;
  }

  // * Fetch plan
  const getPlan = async () => {
    try {
      const { data } = await axios.get(`${environment.BACKEND}/user`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user?.accessToken}`,
        },
      });
      const userData = data.data;
      setPlan(userData.plan);

      setTrialValidUntil(
        calculateDateDifference(userData.subscriptionActiveTill)
      );
    } catch (err) {
      console.log(err.response.data);
    }
  };

  useEffect(() => {
    getExecutions();
    getPlan();
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setIsModalOpen(false);
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  // PostModal component
  const PostModal = ({ post }) => {
    return (
      <div className="modal fixed z-10 inset-0 flex items-center justify-center bg-black bg-opacity-50 overscroll-contain">
        <div className="bg-white rounded-lg p-6 w-3/4 h-5/6 mt-10 mb-10 overflow-y-scroll modal-content-scrollbar overscroll-contain">
          <div className="flex justify-end">
            <button className="text-gray-500 hover:text-gray-700 focus:outline-none ml-2">
              <a href={post.postLink} target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-2" />
              </a>
            </button>
            <button
              className="text-gray-500 hover:text-gray-700 focus:outline-none ml-2"
              onClick={() => setIsModalOpen(false)}
            >
              <FontAwesomeIcon icon={faTimes} className="ml-2" />
            </button>
          </div>
          <div
            className="mb-4 text-lg leading-relaxed formatted-body"
            dangerouslySetInnerHTML={{
              __html: sanitizeBody(post.body),
            }}
          ></div>
          <div className="flex justify-center mt-4">
            <button
              className="px-4 py-2 text-white bg-red-500 rounded-md mr-2"
              onClick={() => setIsModalOpen(false)}
            >
              <FontAwesomeIcon icon={faTimes} className="mr-1" />
              Close
            </button>
            <a
              href={post.postLink}
              target="_blank"
              rel="noopener noreferrer"
              className="px-4 py-2 text-white bg-blue-500 rounded-md"
            >
              Open in Reddit
              <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-2" />
            </a>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`p-12 w-full`}>
      <span className="text-blue-500 underline">
        <NavLink to="/dashboard">
          {" "}
          <FontAwesomeIcon icon={faArrowLeft} className="ml-2 " /> Go back
        </NavLink>
      </span>
      <br />
      <br />
      {plan === "free" ? (
        trialValidUntil < 0 ? (
          <FixedBanner
            icon={<WarningIcon />}
            message={`Your free trial has expired. Please upgrade now to continue generating leads`}
            actionText="Upgrade"
            actionLink="/pricing"
            color="red"
          />
        ) : (
          <FixedBanner
            icon={<BoltIcon />}
            message={`Your free trial expires in ${trialValidUntil} days`}
            actionText="Upgrade"
            actionLink="/pricing"
            color="indigo"
          />
        )
      ) : plan === "" ? null : (
        <FixedBanner
          icon={<VerifiedUserIcon />}
          message={`You are currently on the ${plan?.toUpperCase()} plan`}
          // actionText="Upgrade"
          // actionLink="/pricing"
          color="yellow"
        />
      )}
      <h1 className="text-2xl font-bold mb-4">Execution Details</h1>
      <div className="flex items-center mb-4">
        <h2 className="text-xl mr-2">Lead Matcher Name:</h2>
        <NavLink
          to={`/job-detail?job_id=${job._id}`}
          className="text-blue-500 hover:underline text-xl font-bold"
        >
          {loading && <Skeleton count={1} />}
          {!loading && job.jobName}
        </NavLink>
      </div>
      <div className="mb-4">
        <h3 className="text-lg mb-2">
          <div className="bg-gray-100 rounded-lg p-4">
            <label className="text-sm font-semibold mb-2 bg-gray-600 p-1 pl-2 rounded-lg text-white mr-4">
              Keywords being tracked{" "}
            </label>
            {!loading &&
              keywords.map((keyword, index) => (
                <div key={index} className="inline-flex items-center">
                  <span className="inline-flex items-center bg-indigo-500 text-white px-2 py-1 m-1 rounded-full">
                    {keyword}
                  </span>
                  {index < keywords.length - 1 && (
                    <span className="text-gray-700">or</span>
                  )}
                </div>
              ))}
          </div>
          {jobType === "reddit" && (
            <div className="bg-gray-100 rounded-lg p-4">
              <label className="text-sm font-semibold mb-2 bg-gray-600 p-1 pl-2 rounded-lg text-white mr-4">
                Subreddits{" "}
              </label>
              {!loading &&
                subReddits.map((keyword, index) => (
                  <div key={index} className="inline-flex items-center">
                    <span className="inline-flex items-center bg-indigo-500 text-white px-2 py-1 m-1 rounded-full">
                      {keyword}
                    </span>
                    {index < subReddits.length - 1 && (
                      <span className="text-gray-700">or</span>
                    )}
                  </div>
                ))}
            </div>
          )}
        </h3>
      </div>
      <div className="mb-4">
        <h3 className="text-lg mb-2">
          Execution created at:{" "}
          {!loading && (
            <span className="font-bold">
              {new Date(job.createdAt).toLocaleString([], {
                year: "numeric",
                month: "short",
                day: "numeric",
                hour: "numeric",
                minute: "numeric",
                hour12: true,
              })}
            </span>
          )}
        </h3>
      </div>
      <div className="mb-4">
        <h3 className="text-lg mb-2">
          Number of Posts:{" "}
          {!loading && <code className="font-bold">{posts.length}</code>}
        </h3>
      </div>

      {/* Reddit table */}
      {jobType === "reddit" && (
        <>
          <h2 className="text-xl font-bold mb-4">Reddit Posts</h2>
          <div className="table-container">
            {!loading || <Skeleton count={10} />}
            {!loading && (
              <table
                className={`border border-gray-300  overflow-x-hidden ${
                  isModalOpen ? "overflow-y-hidden" : "overflow-y-auto"
                }`}
              >
                <thead className="sticky top-0">
                  {selectedPosts.length ? (
                    <tr className="bg-gray-100">
                      <td className="py-4 px-4" colSpan={8}>
                        <button
                          className="text-indigo-500 hover:underline mt-2"
                          onClick={() => updateReadStatus(false)}
                        >
                          <div className="flex items-center">
                            <MarkunreadOutlinedIcon
                              fontSize="small"
                              className="mr-1"
                            />
                            <span className="text-sm">Mark as unread</span>
                          </div>
                        </button>
                        <button
                          className="text-indigo-500 hover:underline mt-2 ml-5"
                          onClick={() => updateReadStatus(true)}
                        >
                          <div className="flex items-center">
                            <MarkEmailReadOutlinedIcon
                              fontSize="small"
                              className="mr-1"
                            />
                            <span className="text-sm">Mark as read</span>
                          </div>
                        </button>
                      </td>
                    </tr>
                  ) : (
                    <tr className="bg-gray-100">
                      <th className="py-2 px-4">
                        <input
                          type="checkbox"
                          className="h-4 w-4 accent-gray-700  bg-grey-700 text-red-500  rounded cursor-pointer"
                          onClick={selectAll}
                        />
                      </th>
                      <th className="py-2 px-4">Title</th>
                      <th className="py-2 px-4 w-1/5">Body</th>
                      <th className="py-2 px-4 ">URLs Fetched</th>
                      {/* <th className="py-2 px-4">Emails Fetched</th> */}
                      <th className="py-2 px-4">Upvotes</th>
                      <th className="py-2 px-4">Subreddit Name</th>
                      <th
                        className="py-2 px-4"
                        onClick={() => handleSort("post_created_at")}
                      >
                        <div className="flex items-center">
                          Creation Date
                          <div className="ml-2 flex flex-col">
                            <span
                              className={
                                sortOrder === "asc"
                                  ? "text-blue-500"
                                  : "text-gray-400"
                              }
                            >
                              <FontAwesomeIcon icon={faCaretUp} size="lg" />
                            </span>{" "}
                            <span
                              className={
                                sortOrder === "desc"
                                  ? "text-blue-500"
                                  : "text-gray-400"
                              }
                            >
                              <FontAwesomeIcon icon={faCaretDown} size="lg" />
                            </span>
                          </div>
                        </div>
                      </th>
                      {/* <th className="py-2 px-4">Actions</th> */}
                    </tr>
                  )}
                </thead>
                <tbody>
                  {posts
                    .slice() // Create a copy of the posts array to avoid mutating the original data
                    .sort((a, b) => {
                      // Custom sorting function based on the selected sort field and order
                      // if (sortField === "postTitle") {
                      //   return sortOrder === "asc"
                      //     ? a.postTitle.localeCompare(b.postTitle)
                      //     : b.postTitle.localeCompare(a.postTitle);
                      // } else if (sortField === "upvotes") {
                      //   return sortOrder === "asc"
                      //     ? a.upvotes - b.upvotes
                      //     : b.upvotes - a.upvotes;
                      // } else {
                      // Default sorting by post creation date
                      return sortOrder === "asc"
                        ? Date.parse(a.post_created_at) -
                            Date.parse(b.post_created_at)
                        : Date.parse(b.post_created_at) -
                            Date.parse(a.post_created_at);
                      // }
                    })
                    .map((post) => (
                      <tr
                        key={post._id}
                        className={`white ${
                          post.is_read ? "bg-gray-200" : ""
                        } ${isSelected(post._id)}`}
                      >
                        <td className="py-2 px-4 text-center">
                          <label className="inline-flex items-center">
                            <input
                              type="checkbox"
                              className="h-4 w-4 accent-gray-700  bg-grey-700 text-red-500  rounded cursor-pointer"
                              onChange={handleSelect}
                              checked={selectedPosts.includes(post._id)}
                              value={post._id}
                            />
                          </label>
                        </td>

                        <td className="py-2 px-4 text-left w-3/4">
                          {post.postTitle}{" "}
                          <a
                            onClick={() =>
                              updateReadStatus(true, false, [post._id])
                            }
                            href={post.postLink}
                            className="text-blue-500 hover:underline"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FontAwesomeIcon
                              icon={faExternalLinkAlt}
                              className="ml-2"
                            />
                          </a>
                        </td>
                        <td className="py-2 px-4 text-left w-full">
                          {post.body?.length > 150 && (
                            <>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: truncateString(
                                    sanitizeBody(post.body.trim()),
                                    150
                                  ),
                                }}
                              ></div>
                              {post.body.length > 150 &&
                                post.body.length > 150 && (
                                  <button
                                    className="text-blue-500 hover:underline mt-2"
                                    onClick={() =>
                                      togglePostExpansion(post._id)
                                    }
                                  >
                                    Show more
                                  </button>
                                )}
                            </>
                          )}
                        </td>
                        <td className="py-2 px-4 text-center">
                          {renderUrls(post.urls)}
                        </td>
                        {/* <td className="py-2 px-4 text-center">
                      {post.emails.length > 0 ? (
                        post.emails.map((email, index) => (
                          <p key={index}>{email}</p>
                        ))
                      ) : (
                        <p>--</p>
                      )}
                    </td> */}
                        <td className="py-2 px-4 text-center">
                          {post.upvotes}
                        </td>
                        <td className="py-2 px-4 text-center">
                          {" "}
                          <a
                            href={`https://www.reddit.com/${post.subreddit_name}`}
                            className="text-blue-500 hover:underline"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {post.subreddit_name}
                          </a>
                        </td>
                        <td className="py-2 px-4 text-center">
                          {new Date(
                            Date.parse(post.post_created_at)
                          ).toLocaleString([], {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit",
                          })}
                        </td>
                        {/* <td className="py-2 px-4 text-center">
                    <a
                      href={post.postLink}
                      className="text-blue-500 hover:underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Open in Reddit
                    </a>
                  </td> */}
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </div>
        </>
      )}

      {/* LinkedIn table */}
      {jobType === "linkedin" && (
        <>
          <h2 className="text-xl font-bold mb-4">LinkedIn Posts</h2>
          <div className="table-container">
            {!loading || <Skeleton count={10} />}
            {!loading && (
              <table
                className={`border border-gray-300  overflow-x-hidden ${
                  isModalOpen ? "overflow-y-hidden" : "overflow-y-auto"
                }`}
              >
                <thead className="sticky top-0">
                  {selectedPosts.length ? (
                    <tr className="bg-gray-100">
                      <td className="py-4 px-4" colSpan={8}>
                        <button
                          className="text-indigo-500 hover:underline mt-2"
                          onClick={() => updateReadStatus(false)}
                        >
                          <div className="flex items-center">
                            <MarkunreadOutlinedIcon
                              fontSize="small"
                              className="mr-1"
                            />
                            <span className="text-sm">Mark as unread</span>
                          </div>
                        </button>
                        <button
                          className="text-indigo-500 hover:underline mt-2 ml-5"
                          onClick={() => updateReadStatus(true)}
                        >
                          <div className="flex items-center">
                            <MarkEmailReadOutlinedIcon
                              fontSize="small"
                              className="mr-1"
                            />
                            <span className="text-sm">Mark as read</span>
                          </div>
                        </button>
                      </td>
                    </tr>
                  ) : (
                    <tr className="bg-gray-100">
                      <th className="py-2 px-4">
                        <input
                          type="checkbox"
                          className="h-4 w-4 accent-gray-700  bg-grey-700 text-red-500  rounded cursor-pointer"
                          onClick={selectAll}
                        />
                      </th>
                      <th className="py-2 px-4 w-1/5">Posted By</th>
                      <th className="py-2 px-4 w-4/5">Body</th>
                    </tr>
                  )}
                </thead>
                <tbody>
                  {posts
                    .slice()
                    .sort((a, b) => {
                      return sortOrder === "asc"
                        ? Date.parse(a.post_created_at) -
                            Date.parse(b.post_created_at)
                        : Date.parse(b.post_created_at) -
                            Date.parse(a.post_created_at);
                      // }
                    })
                    .map((post) => (
                      <tr
                        key={post._id}
                        className={`white ${
                          post.is_read ? "bg-gray-200" : ""
                        } ${isSelected(post._id)}`}
                      >
                        <td className="py-2 px-4 text-center">
                          <label className="inline-flex items-center">
                            <input
                              type="checkbox"
                              className="h-4 w-4 accent-gray-700  bg-grey-700 text-red-500  rounded cursor-pointer"
                              onChange={handleSelect}
                              checked={selectedPosts.includes(post._id)}
                              value={post._id}
                            />
                          </label>
                        </td>

                        <td className="py-2 px-4 text-center  w-1/5">
                          <a
                            onClick={() =>
                              updateReadStatus(true, false, [post._id])
                            }
                            href={post.linkedInProfileURL}
                            className="text-blue-500 hover:underline"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {post.user}{" "}
                          </a>
                        </td>
                        <td className="py-2 px-4 text-left w-full">
                          <>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: truncateString(
                                  sanitizeBody(post.body?.trim()),
                                  150
                                ),
                              }}
                            ></div>
                            <a
                              className="text-blue-500 hover:underline mt-2"
                              onClick={() =>
                                updateReadStatus(true, false, [post._id])
                              }
                              href={post.postLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <a
                                onClick={() =>
                                  updateReadStatus(true, false, [post._id])
                                }
                                href={post.postLink}
                                className="text-blue-500 hover:underline"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <FontAwesomeIcon
                                  icon={faExternalLinkAlt}
                                  className="ml-2"
                                />
                              </a>
                            </a>
                          </>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </div>
        </>
      )}

      {isModalOpen && (
        <PostModal post={posts.find((post) => post._id === expandedPostId)} />
      )}
    </div>
  );
};

export default ExecutionPage;
