import { createContext, useReducer, useEffect } from "react";
import Cookies from "js-cookie";

export const AuthContext = createContext();

export const authReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      return { user: action.payload };
    case "LOGOUT":
      return { user: null };
    default:
      return state;
  }
};

// eslint-disable-next-line react/prop-types
export const AuthContextProvider = ({ children }) => {
  let userCookie = Cookies.get("user");
  if (userCookie) userCookie = JSON.parse(userCookie);

  const [state, dispatch] = useReducer(authReducer, {
    user: userCookie,
  });

  useEffect(() => {
    const user = userCookie;
    if (user)
      dispatch({
        type: "LOGIN",
        payload: user,
      });
  }, []);

  // console.log("AuthContext state:", state);

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
